/* stylelint-disable selector-class-pattern */
.order-summary-accordion {
  &.MuiAccordion-root {
    background-color: map-get($colors, 'transparent');
    box-shadow: none;
    
    &:before {
      content: none;
    }
  }
}

.order-summary-accordion-summary {
  &.MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionSummary-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.order-summary-accordion-details {
  &.MuiAccordionDetails-root {
    padding: 0;
  }
}

.summary-wrapper {
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: $zi-customization-summary-wrapper;
  
  @include media($mq-md) {
    position: sticky;
    top: $space-24;
    width: auto;
  }

  .continue-button {
    width: 100%;
    
    .button-primary-face {
      justify-content: center;
    }
  }
  
  .continue-button-desktop {
    display: none;
    
    @include media($mq-md) {
      display: block;
      margin-top: $space-24;
    }
  }

  .order-summary {
    border: 1px solid map-get($colors, 'n1-200');
    border-radius: 8px;
    margin: $space-16 0;
    padding: $space-4 $space-24;
  }

  .order-summary-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: $space-8;
    
    &.blank {
      color: map-get($colors, 'n1-300');
    }

    > :first-child {
      padding-right: $space-12; // this is so the option text can't go right next to the price
      text-align: left;
    }

    .word-delete-wrapper {
      display: inline-flex;
    }

    .delete-button {
      margin-left: 4px;


      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  .order-summary-row.add-ons-list {
    color: map-get($colors, 'n1-400');
    margin: $space-16 0 $space-8;
  }

  // for preview only
  .pli-container {
    align-items: center;
    background: rgba(0, 0, 0, 0);
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0;
    position: absolute;
    width: 100%;
    // Future possibility to add contrast to the loading indicator. stashing for now
    // background: radial-gradient(circle, map-get($colors, 'shadow-30') 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 100%);

    @include media($mq-md) {
      min-height: 200px;
    }

    @include media($mq-lg) {
      margin-top: -40px;
      min-height: 300px;
    }
  }

  .circle-loading-indicator {
    border-right: 5px solid transparent;
    border-top: 5px solid var(--primary-color, map-get($colors, 'n1-500'));
    height: 55px;
    width: 55px;
  }

  .preview-window {
    background-color: map-get($colors, 'white');
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    height: 198px;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    
    @include media($mq-md) {
      box-shadow: none;
      height: auto;
      position: relative;
      top: 0;
    }
  }

  .preview-window .order-summary-wrapper {
    background-color: rgba(map-get($colors, 'white'), 0.95);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    display: none;
    height: 100%;
    overflow-y: scroll;
    padding: $space-16 $space-24;
    position: absolute;
    text-align: left;
    top: 40px;
    width: 100%;
    
    @include media($mq-md) {
      display: none;
    }
    
    .subtotal {
      display: none;
    }
  }

  .preview-window .order-summary {
    border: 0;
    margin-top: $space-16;
    padding: 0;
    
    .order-summary-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: $space-8;
    }
  }

  .preview-window.open {
    .order-summary-wrapper {
      display: block;
    }
  }


  .preview-image-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    transition: all 0.2s ease-out;
    width: 100%;

    img {
      transition: opacity 0.2s ease-out;
    }
  }


  .total {
    align-items: center;
    margin-top: $space-16;
    
    @include media($mq-md) {
      padding: 0 $space-24;
    }


    .order-total-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: right;
      text-align: right;
    }
  }

  .alt-mobile-preview {
    display: none;
  }
  
  .alt-mobile-preview {
    &.open {
      background-color: transparent;
      box-shadow: none;
      display: block;
    }
  }
  
  .alt-mobile-preview {
    &.alt-mobile-preview-open {
      align-items: center;
      background-color: map-get($colors, 'white');
      bottom: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: fixed;
      width: 100%;
      z-index: $zi-mobile-preview;
  
      .preview-image-wrapper {
        height: 75%;
  
        img.sheet {
          max-width: 335px;
        }
  
        img.round {
          max-width: 410px;
        }
      }
  
      .preview-layered {
        margin-top: -90px;
      }
  
      .alt-mobile-preview-body {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0 $wrapper-padding;
        width: 100%;
  
        .close-btn {
          height: $space-40;
          margin: $space-16 0 $space-32 0;
          width: 100%;
        }
      }
    }
  }

  // Order summary section
  .summary-column {
    display: none;
    
    @include media($mq-md) {
      display: block;
      padding-top: 0;
      text-align: center;
    }

    .customization-preview-container {
      overflow: hidden;
      position: relative;
    }
  }

  .summary-column .order-summary-wrapper {
    display: none;
    
    @include media($mq-md) {
      display: block;
    }
  }

  .summary-toggle {
    display: none;
  
    @include media($mq-md) {
      align-items: center;
      color: map-get($colors, 'n1-500');
      display: flex;
      justify-content: space-between;
      // below are tricks to make the clickable area expand into the padding of the container
      margin: -#{$space-16} -#{$space-24}; // have to use #{} or the css doesn't compile correctly
      padding: $space-16 $space-24;
      width: calc(100% + $space-48);
    }
  
    svg path {
      fill: map-get($colors, 'p1-500');
    }
  }
}

.summary-container {
  @include media($mq-md) {
    padding: $space-12 0;
  }
}

.mobile-summary-bar {
  background-color: map-get($colors, 'n1-100');
  position: sticky;
  z-index: $zi-mobile-customizer-summary-bar;
  
  @include media($mq-md) {
    display: none;
  }
}

.mobile-summary-bar-sections {
  align-items: center;
  background-color: map-get($colors, 'n1-100');
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
  width: 100%;
  
  .half {
    flex: 1 0;
    text-align: center;
  }

  .toggle-button {
    align-items: center;
    color: map-get($colors, 'n1-500');
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    .svg {
      margin-left: $space-4;
    }
  }
}

// only show on mobile - no accordion
.order-summary-mobile {
  @include media($mq-md) {
    display: none;
  }
}

// only show on tablet+ - has accordion
.order-summary-accordion {
  display: none;

  @include media($mq-md) {
    display: block;
  }
}

.preview-image-wrapper img {
  max-width: 240px;
  min-width: 100px;

  @include media($mq-md) {
    max-width: 280px;
    min-height: 120px;
  }

  @include media($mq-lg) {
    max-width: 360px;
  }
}

.preview-layered {
  margin-top: -50px;

  &.enhanced.round {
    margin-top: -20px;

    @include media($mq-md) {
      margin-bottom: 0;
      margin-top: -25px;
    }
  } 

  &.cupcake {
    @include media($mq-md) {
      margin-top: -35px;
    }
  }

  &.enhanced {
    @include media($mq-lg) {
      margin-top: -70px;
    }
  }
  
  &.enhanced.sheet {
    margin-top: -35px;
  }

  &.cupcake {
    margin-top: -10px;
  }
}

.preview-standard.round {
  max-width: 185px;

  @include media($mq-md) {
    margin-bottom: 25px;
    margin-top: 0;
    max-width: 240px;
  }
}

.preview-standard {
  margin: -20px 0;

  @include media($mq-md) {
    margin-top: -40px;
  }
}

.preview-standard.custom {
  max-width: 170px;

  @include media($mq-md) {
    margin: 0 0 $space-16 0;
    max-width: 240px;
  }

  @include media($mq-lg) {
    max-width: 270px;
  }
}

.add-ons-row {
  .typography {
    display: flex;
  }
}
