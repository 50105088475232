// ---------------------------------------------------------
/*
    appearance mixin
*/
// ---------------------------------------------------------

@mixin appearance($type) {
  -webkit-appearance: $type;
  -moz-appearance: $type;
  appearance: $type;
}
