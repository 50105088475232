html,
body {
  // background-color: map-get($colors, 'white');
}

.typography-heading-900 {
  font-size: rem(32px);
  font-weight: 700;
  line-height: rem(38px);

  @include media($mq-md) {
    font-size: rem(65px);
    line-height: rem(72px);
  }
}

.typography-heading-800 {
  font-size: rem(24px);
  font-weight: 700;

  @include media($mq-md) {
    font-size: rem(42px);
    line-height: rem(50px);
  }
}

.typography-heading-700 {
  font-size: rem(18px);
  font-weight: 700;

  @include media($mq-md) {
    font-size: rem(32px);
  }
}

.typography-heading-600 {
  font-size: rem(20px);
  font-weight: 700;

  @include media($mq-md) {
    font-size: rem(24px);
  }

  &.bolded {
    font-weight: bold;
  }
}

.typography-heading-400 {
  font-size: rem(18px);

  @include media($mq-md) {
    font-size: rem(18px);
    letter-spacing: 0.01em;
  }
}

.typography-body,
.typography-body-bold,
.typography-body-medium {
  font-size: rem(16px);
  letter-spacing: 0.01em;
  line-height: rem(24px);
}

.typography-body {
  font-weight: 400;
}

.typography-body-bold {
  font-weight: 700;
}

.typography-body-medium {
  font-weight: 500;
}

.typography-small,
.typography-small-medium,
.typography-small-bold {
  font-size: rem(14px);
  line-height: rem(22px);
}

.typography-small {
  font-weight: 400;
}

.typography-small-medium {
  font-weight: 500;
}

.typography-small-bold {
  font-weight: 700;
}

.typography-eyebrow,
.typography-eyebrow-medium,
.typography-eyebrow-uppercase {
  font-size: rem(12px);
  letter-spacing: 0.06em;
  line-height: rem(18px);
}

.typography-eyebrow-uppercase {
  font-weight: 400;
  text-transform: uppercase;
}

.typography-eyebrow {
  font-weight: 400;
}

.typography-eyebrow-medium {
  font-weight: 500;
}

.typography-step-number {
  font-size: rem(64px);
  font-weight: bold;
  line-height: rem(64px);

  @include media($mq-md) {
    font-size: rem(96px);
    line-height: rem(96px);
  }
}

// Colors
@each $name, $color in $colors {
  .color-#{$name},
  .color-#{$name}:before {
    color: $color;
  }

  .color-bg-#{$name} {
    background-color: $color;
  }
}
