.search-input-svg-bg {
  align-items: center;
  background-color: map-get($colors, 'white');
  border-bottom-right-radius: 500px;
  border-top-right-radius: 500px;
  color: map-get($colors, 'white');
  display: flex;
  justify-content: center;
  padding: 4px 0 0;
  width: 65px;
}

.text-input-medium {
  .search-input-svg-bg {
    height: 48px;
  }
}

.mobile-search-form {
  height: 100%;
}

.search-result-count {
  margin: $space-16 0 $space-32 0;

  @include media($mq-md) {
    margin: $space-40 0 $space-32;
  }
}
