.color-badge {
  border: 1px solid map-get($colors, 'transparent');
  border-radius: 4px;
  color: map-get($colors, 'white');
  margin-left: $space-8;
  padding: 3px $space-8;
  text-align: center;
  transition: all 0.2s ease-out;

  &.blank {
    border: 1px dotted map-get($colors, 'n1-200');
    color: map-get($colors, 'n1-300');
  }

  &.invert-text-color {
    border: 1px solid map-get($colors, 'n1-200');
    color: map-get($colors, 'n1-500');
  }
}
