.error-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;

  @include media($mq-xl) {
    flex-direction: row;
    justify-content: space-evenly;
    padding: $space-40 0;
  }

  .text-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: $space-16 $space-24 $space-40;
    text-align: center;

    @include media($mq-xl) {
      align-items: flex-start;
      text-align: start;
    }

    .error-code {
      padding-bottom: $space-24;
      padding-left: 0;

      @include media($mq-xl) {
        padding-bottom: $space-32;
      }
    }

    .error-description {
      padding-bottom: $space-8;
    }

    .error-body {
      max-width: 327px;

      @include media($mq-md) {
        max-width: 548px;
      }

      @include media($mq-xl) {
        max-width: none;
      }
    }

    .error-cta-wrapper {
      display: flex;
      padding-top: $space-32;

      :first-child {
        margin-right: $space-16;
      }
    }
  }

  .image-wrapper {
    height: 155px;
    position: relative;
    width: 187px;

    @include media($mq-md) {
      height: 258px;
      width: 310px;
    }

    @include media($mq-xl) {
      height: 450px;
      width: 540px;
    }

    img {
      object-fit: contain;
    }
  }
}
