/* stylelint-disable selector-class-pattern */
.confirm-update-location-modal {
  text-align: center;

  @include media($mq-md) {
    border-radius: 8px;
  }

  .heading {
    margin-bottom: $space-16;
  }

  .body {
    margin-bottom: $space-24;
  }

  .yes-button {
    margin-bottom: $space-16;
  }

  .MuiDialogContent-root {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: $space-64 $space-24 $space-32;

    @include media($mq-md) {
      width: 540px;
    }
  }
}
