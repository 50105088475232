.location-autocomplete-wrapper {
  position: relative;

  @include media($mq-md) {
    margin: 0 16px 0 0;
  }

  /* stylelint-disable selector-class-pattern, max-nesting-depth */
  .MuiFormHelperText-root.Mui-error {
    bottom: -5px;
    margin-left: 2px;
    margin-top: -10px;
    position: absolute;
    white-space: nowrap;
  }

  .geolocation-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  
    &:hover {
      cursor: pointer;
    }

    &.loading {
      .svg g {
        fill: var(--primary-color);
      }
    }
  }

  .loading-autocomplete {
    display: flex;
    height: 20px;
    position: absolute;
    right: 13px;
    top: 37%;
    transform: none;
    width: 20px;
  }
}

.location-autocomplete-container {
  display: flex;
}

.location-autocomplete {
  border: 1px solid map-get($colors, 'n1-500');
  border-radius: 8px;
  box-sizing: border-box;
  height: 48px;
  margin: $space-16 0;
  outline: none;
  padding: 0 $space-40 0 $space-16;
  text-overflow: ellipsis;
  width: 100%;
  
  @include media($mq-md) {
    width: 240px;
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
}
