// Search page styles
.search-results-empty {
  text-align: center;
}

.search-cookie-img {
  margin: $space-32 auto 0 auto;
  max-width: 470px;
}

.search-results-empty-headline {
  margin: $space-32 0 0 0;
}

.search-results-empty-subhead,
.search-results-empty-body {
  margin: $space-8 0 0 0;
}
