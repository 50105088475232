// ---------------------------------------------------------
/*
    timing for transitions

    work with design team to figure out what are timings
    need to be for baseline transitions as well as more
    advanced uses
*/
// ---------------------------------------------------------

$timing-0: 0.2s ease-out 0s;
$timing-1: 0.4s ease-out 0s;
$timing-2: 0.6s ease-out 0s;
$timing-3: 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
}
