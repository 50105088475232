$kiosk-bar-height: 40px;

.kiosk-mode-bar {
  background-color: map-get($colors, 'n1-500');
  color: map-get($colors, 'white');
  height: $kiosk-bar-height;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: $zi-kiosk-bar;

  // push header and configurator order summary down
  @include media($mq-ltmd) {
    ~ .header-container {
      top: $kiosk-bar-height;
    }

    ~ .customize-wrapper {
      .summary-wrapper {
        top: $header-sm-height + $kiosk-bar-height;
      }
    }
  }

  // push mobile search bar down
  @include media($mq-ltlg) {
    ~ .header-container {
      .mobile-search-input-wrapper {
        &.visible {
          top: $kiosk-bar-height;
        }
      }
    }
  }
}

.kiosk-bar-content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  button {
    color: map-get($colors, 'white');
    display: flex;
  }
}

.modal.kiosk-confirm-reset-modal {
  padding: $space-64 $space-24;

  @include media($mq-md) {
    padding: $space-64 $space-40;
  }

  @include media($mq-md) {
    max-width: 550px;
  }

  .kiosk-confirm-reset-modal-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .headline {
    margin-bottom: $space-16;
  }

  .subhead {
    display: block;
    margin-bottom: $space-24;
  }

  .ctas {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    @include media($mq-lg) {
      flex-direction: row;
    }

    button {
      width: 100%;

      @include media($mq-md) {
        max-width: 240px;
      }

      @include media($mq-lg) {
        max-width: unset;
      }
    }
  }
}
