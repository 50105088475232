// Note:
// these keys need to be in quotes to work correctly
$base-colors: (
  'p1-500':            #3F385A,
  'p1-400':            #8071BA,
  'p2-500':            #64ADAD,
  'p2-500-accessible': #57A0A0,
  'p2-300':            #C4E2D9,
  'p2-200':            #ECF5F2,
  'p3-500':            #007D7D,
  's1-500':            #E8654E,
  's1-400':            #F08977,
  's2-500':            #DA0E0E,
  't1':                #F6DDE5,
  't2':                #ECE4E1,
  't3':                #FCF7FD,
  't4':                #EEFCFF,
  't5':                #FBF8EA,
  't6':                #FAF0EC,
  'n1-100':            #F8F8F8,
  'n1-200':            #D6D4DE,
  'n1-300':            #78757E,
  'n1-400':            #4E4B5B,
  'n1-500':            #333040,
  'n1-600':            #4D4D4D,
  'n1-700':            #525252,
  'shadow-25':         rgba(0, 0, 0, 0.25),
  'shadow-30':         rgba(0, 0, 0, 0.3),
  'bg-pink':           #FCF7FD,
  'bg-blue':           #EEFCFF,
  'bg-green':          #ECF5F2,
  'bg-yellow':         #FBF8EA,
  'bg-purple':         #F4F1FD,
);

$extended-colors: (
  'black':              #000000,
  'carousel-border':    #C4C4C4,
  'grey':               #E1E1E1,
  'input-placeholder':  #78757E,
  'off-white':          #FBFBFC,
  'transparent':          transparent,
  'white':              #FFFFFF,
  'background-tile-warning': #D23235,
  'blue-textfield-default': #1976D2
);

$colors: map-merge($base-colors, $extended-colors);
