.upload-photo-page {
  .choose-photo-link {
    color: map-get($colors, 'n1-400');
    cursor: pointer;
    font-size: rem(18px);
    font-weight: bold;
    text-decoration: underline;
  }
  
  .choose-photo-preview {
    margin-left: $space-16;
  }
  
  .file-upload-error {
    color: map-get($colors, 's2-500');
    justify-content: center;
    margin: 0 0 2rem 0;

    @include media($mq-lg) {
      justify-content: left;
    }
  }

  .body-section {
    text-align: center;

    @include media($mq-lg) {
      text-align: left;
    }
  }

  .upload-section {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include media($mq-lg) {
      align-items: flex-start;
    }
  }

  .photo-upload-btn {
    padding: $space-16 $space-96;
  }

  .upload-section > * {
    margin-bottom: $space-32;
  }
}
