/* stylelint-disable selector-class-pattern */
// sets the height of the desktop header-top
$header-lg-height: 96px;

// sets the height of the tablet header
$header-md-height: 64px;

// sets the height of the mobile header
$header-sm-height: 50px;

$logo-sm-width: 125px;
$logo-lg-width: 214px;


.header-container {
  position: sticky;
  top: 0;
  z-index: $zi-header;

  @include media($mq-md) {
    position: relative;
  }
}

.header {
  background-color: map-get($colors, 'white');
  box-shadow: 0 0 16px rgb(0 0 0 / 10%);
  position: relative;
  width: 100%;
  z-index: $zi-header;

  .MuiTextField-root.keyboard-active {
    .MuiInputBase-input {
      &:focus-visible {
        outline: 2px auto map-get($colors, 'blue-textfield-default');
        z-index: $zi-search-text-input;
      }
    }
  }
}

.header-top {
  align-items: center;
  display: flex;
  height: $header-sm-height;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $max-container;
  width: 100%;

  @include media($mq-md) {
    height: $header-md-height;
  }

  @include media($mq-lg) {
    height: $header-lg-height;
  }
}

.header-sm {
  display: block;

  @include media($mq-lg) {
    display: none;
  }
}

.header-lg {
  display: none;
  min-height: 146px;

  @include media($mq-lg) {
    display: block;
  }
}

.search-input-svg {
  color: var(--primary-color, map-get($colors, 'p1-500'));
}

.header-hamburger {
  path {
    fill: var(--primary-color, map-get($colors, 'p1-500'));
  }
}

.header-logo-container {
  display: block;
  height: 45px;
  max-width: $logo-lg-width;
  position: relative;

  @include media($mq-lg) {
    height: 65px;
    max-width: $logo-lg-width;
  }
}

.header-logo-image {
  display: block;
  object-fit: contain;
  position: relative !important;
}

.header-search-input {
  flex-grow: 0;

  @include media($mq-lg) {
    flex-grow: 1;
    margin: 0 0 0 auto;
    max-width: 460px;
  }
}

.header-nav {
  background-color: var(--primary-color, map-get($colors, 'n1-100'));
}

.header-nav-list {
  display: flex;
  margin: 0 auto;
  max-width: $max-container;

  li {
    margin: 0 $space-32 0 0;

    &:last-child {
      margin: 0;
    }
  }
}

.header-nav-anchor {
  color: var(--primary-label-color, map-get($colors, 'n1-300'));
  display: block;
  font-size: 17px;
  font-weight: 500;
  padding: $space-12 0;
  position: relative;
}

.header-nav-anchor-selected {
  font-weight: 700;

  .header-nav-highlight {
    display: block;
  }
}

.header-nav-highlight {
  background-color: var(--primary-label-color, map-get($colors, 'n1-500'));
  border-radius: 4px 4px 0 0;
  bottom: 0;
  content: '';
  display: none;
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
}

// NOTE: leaving this here for now
// .header-checkout {
//   display: flex;
//   height: 56px;

//   @include media($mq-md) {
//     height: 64px;
//   }

//   @include media($mq-lg) {
//     height: $header-lg-height;
//   }

//   .logo-btn {
//     height: 19px;
//   }

//   .svg {
//     width: 80px !important;

//     @include media($mq-md) {
//       width: 120px !important;
//     }
//   }

//   .header-inner {
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr;
//   }

//   .typography {
//     color: #57A0A0; // Manually darkened from `color: map-get($colors, p2-500)` to pass accessibility contrast issues
//     justify-self: center;
//   }
// }
