// ---------------------------------------------------------
/*
    z-indexes are defined here
*/
// ---------------------------------------------------------

// leave a comment denoting what each z-index is being used for
$zi-79: 79;
$zi-89: 89;
$zi-99: 99;

// other z-indexes can be added below

$zi-mobile-preview: -1; // needs to be below the elements above it
$zi-file-upload: -1; // need to hide the file upload element
$zi-0: 0;
$zi-pay-title: 1; // title on checkout page that needs to be above hr element
$zi-search-autocomplete: 1; // needs to be on top of nav bar
$zi-customizer-text-input-wrapper: 3; // configurator text area wrapper
$zi-mobile-search: 200; // mobile search backdrop wrapper
$zi-mobile-search-input-wrapper: 201; // wrapper around the actual input
$zi-configurator-loader-wrapper: 900; // wrapper for configurator section loaders
$zi-customization-summary-wrapper: 1000; // wrapper for the order summary box
$zi-mobile-customizer-summary-bar: 1299; // sticky summary bar on mobile customizer
$zi-header: 1300; // header
$zi-kiosk-bar: 1400; // kiosk bar
$zi-mobile-drawer: 1401; // mobile slide out nav
$zi-dialog-root: 1500; // dialogs/modals should be above everything
$zi-filters-menu: 2; // filters menu on subcategories & search
$zi-search-text-input: 1 // focus-visible outline needs to be above the input element 


