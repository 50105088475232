// ---------------------------------------------------------
/*
    baseline variables for 8px spacing

    4px added as it can see use from time to time when dealing
    with buttons and / or icons

    12px added as it can see use from time to time when dealing
    with buttons and / or icons
*/
// ---------------------------------------------------------

$space-4: rem(4px);
$space-8: rem(8px);
$space-12: rem(12px);
$space-16: rem(16px);
$space-20: rem(20px);
$space-24: rem(24px);
$space-28: rem(28px);
$space-32: rem(32px);
$space-40: rem(40px);
$space-44: rem(44px);
$space-48: rem(48px);
$space-56: rem(56px);
$space-64: rem(64px);
$space-72: rem(72px);
$space-80: rem(80px);
$space-96: rem(96px);
