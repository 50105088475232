$controls-column-padding: 218px;

.divider {
  background-color: map-get($colors, 'n1-200');
  height: 24px;
  margin: $space-8;
  width: 1px;
}

.customize-wrapper {
  // Allows the normally padded containers to be full width on mobile
  padding-top: 0 !important;

  @include media($mq-ltmd) {
    padding: 0 !important;
  }

  @include media($mq-md) {
    padding-left: $wrapper-padding;
    padding-right: $wrapper-padding;
  }
}

.customize-wrapper-grid {
  @include media($mq-ltmd) {
    flex-direction: column-reverse;
  }
}

// Main page wrapper
.customize {
  min-height: 100%;
  
  @include media($mq-md) {
    padding: $wrapper-padding 0;
  }

  .subhead {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: $space-16;
    margin-top: $space-8;

    .typography {
      flex: 1;
    }
  }
}

.customize-heading {
  display: none;

  @include media($mq-md) {
    display: block;
  }
}

.scroll-anchor {
  height: 0;
  margin-top: -286px; // offset the sticky elements on mobile
  position: absolute;
  visibility: hidden;

  @include media($mq-md) {
    margin-top: 0;
  }
}

// Customization controls section
.controls-column {
  padding: $controls-column-padding 0 0 0;

  @include media($mq-md) {
    padding: 0;
  }

  &.alt-mobile-preview {
    padding: $space-44 $space-16;

    @media only screen and (max-width: 768px) {
      width: 100vw;
    }

    @include media($mq-md) {
      padding: 0;
    }
  }
}

.customize-section {
  border: 1px solid map-get($colors, 'n1-200');
  color: map-get($colors, 'n1-300');
  margin-top: $space-24;
  padding: $space-16 $space-24;

  @include media($mq-md) {
    border-radius: 8px;
  }

  &.active {
    background-color: map-get($colors, 'n1-100');
    border-color: map-get($colors, 'n1-100');
    color: map-get($colors, 'n1-400');
    transition: all 0.2s ease-in-out;

    .options-grid {
      display: flex;
      margin-top: $space-4;
    }

    .subhead {
      display: flex;
    }

    .top-border-title,
    .bottom-border-title,
    .color-badge {
      display: inline;
    }

    .border-title-wrapper {
      margin: $space-12 0 $space-16 0;
    }

    .section-header {
      margin: 0 0 $space-16 0;
    }
  }

  .section-calories {
    margin: 0 0 $space-8 0;
  }

  .options-grid {
    display: none;
  }

  .subhead,
  .top-border-title,
  .bottom-border-title,
  .color-badge {
    display: none;
  }
}

.border-color {
  border: 2px solid transparent;
  border-radius: 50%;
  padding: 2px;
  position: relative;
  transition: border 0.2s ease-out;

  .circle {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  &.selected {
    border: 2px solid map-get($colors, 'n1-500') !important;
  }
}

.color-dot {
  border: 1px solid transparent;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-left: $space-8;
  width: 10px;

  &.white {
    border: 1px solid map-get($colors, 'n1-200');
  }
}

// Gray out colors if disabled
.options-grid.disabled .border-color {
  filter: saturate(0);
  opacity: 0.5;
}


.brown-circle {
  background-color: #492C24;
  border-radius: 50%;
  height: 16px;
  margin-right: $space-8;
  margin-top: $space-4;
  width: 16px;    
}

.top-border-title {
  margin-bottom: $space-16;
}

.bottom-border-title {
  margin: $space-16 0;
}

.continue-button-mobile-wrapper {
  margin: $space-24 $wrapper-padding;

  @include media($mq-md) {
    display: none;
  }

  &.slim {
    margin: $space-24 0 $space-16 0;
  }
}

.continue-button-mobile {
  display: block;
  width: 100%;

  @include media($mq-md) {
    display: none;
  }
}

.preview-button-mobile {
  height: $space-40;
  margin: 0 0 $space-24 0;
  width: 100%;

  @include media($mq-md) {
    display: none;
  }
}
