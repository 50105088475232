.location-list-item {
  background-color: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: 8px;
  margin: 0 0 $space-16 0;
  padding: 16px 24px;
  position: relative;

  &:last-child {
    margin: 0;
  }

  // create fake "inset" 2px border
  &.selected {
    border: 1px solid map-get($colors, 'n1-500');

    .location-list-item-btn {
      border: 1px solid map-get($colors, 'n1-500');
    }
  }
}

.button {
  &.location-cta {
    height: $space-44;
    padding: 0;
    position: relative;
    width: 100%;
  }

  &.location-cta-current {
    background-color: map-get($colors, 'white');
    border: 1px solid map-get($colors, 'n1-300');
    color: map-get($colors, 'n1-300');
    pointer-events: none;
  }
}

.location-list-item-btn {
  background-color: map-get($colors, 'transparent');
  border: 1px solid map-get($colors, 'transparent');
  border-radius: 8px;
  bottom: 0;
  cursor: default;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.location-title-wrapper {
  display: inline;
  line-height: 1;
  position: relative;
}

.location-title {
  align-items: center;
  color: map-get($colors, 'n1-500');
  display: inline-flex;
  font-size: rem(18px);
  font-weight: 500;
  text-decoration: none;
}

.location-list-item-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 0;
  pointer-events: none;
  position: relative;

  .right {
    flex-basis: 25%;
    text-align: right;
  }
}

.location-phone {
  margin: 16px 0 0 0;
  pointer-events: auto;
}

.location-distance {
  font-weight: 500;
}
