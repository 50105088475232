.loader-container {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  z-index: 1000;

  &.position-relative {
    opacity: 1;
    position: relative;
  }

  // Configurator preview
  &.pli-container {
    right: 0;
    top: 0;
    transform: none;
  }

  .svg-spin {
    display: block;
    margin: 0 auto 8px auto;
    position: relative;
    width: 48px;
    
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  .circular {
    animation: rotate-loader 2s linear infinite;
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center center;
    width: 100%;
  }

  .path {
    animation: dash-loader 1.5s ease-in-out infinite;
    // Use brand color
    stroke: var(--primary-color, map-get($colors, 'n1-500'));
  }

  @keyframes rotate-loader {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash-loader {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
}

.loading-button {
  position: relative;
  right: 0;
  top: 4px;
  transform: none;

  .path {
    stroke: map-get($colors, 'white');
  }
}

.locator-loading-button {
  position: relative;
  right: 0;
  top: 4px;
  transform: none;

  .path {
    stroke: map-get($colors, '--primary-color');
  }
}
