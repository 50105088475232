/* stylelint-disable selector-class-pattern */

// overrides for MUI accordions
.accordion {
  &.MuiAccordion-root {
    background-color: map-get($colors, 'transparent');
    box-shadow: none;
    
    &:before {
      content: none;
    }
  }

  // remove padding from last p
  // in last .accordion
  &:last-of-type {
    p {
      &:last-of-type {
        margin: 0;
      }
    }
  }
}

.accordion-summary {
  &.MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionSummary-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.accordion-toggle-icon {
  line-height: 1;
}

.accordion-details {
  &.MuiAccordionDetails-root {
    padding: 0;
  }

  p {
    margin: 0 0 $space-20 0;
  }
}

.accordion-hr {
  border: 0;
  border-top: 1px solid map-get($colors, 'n1-200');
  margin: $space-20 0;
}
