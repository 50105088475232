.location-container {
  display: flex;

  @include media($mq-md) {
    padding: $space-24;
  }

  @include media($mq-lg) {
    padding: $space-24 $space-64;
  }
}

.location-page {
  border-radius: 8px;
  width: 100%;

  @include media($mq-md) {
    border: 1px solid map-get($colors, 'n1-200');
  }

  .location-container {
    flex-direction: column;
    justify-content: space-between;

    @include media($mq-md) {
      flex-direction: row;
    }
  }

  .detail-wrapper {
    width: 100%;

    @include media($mq-md) {
      max-width: 360px;
      padding-right: $space-64;
    }

    @include media($mq-lg) {
      padding-right: 0;
    }

    .logo {
      height: 40px;
      margin-bottom: $space-8;
      object-fit: contain;
      position: relative;

      .logo-img {
        width: auto !important;
      }
    }

    .location-details {
      display: grid;
      gap: $space-24;

      .phone-link:hover,
      .location-link:hover {
        text-decoration: underline;
      }
    }
  }

  .hours-wrapper {
    display: grid;
    gap: $space-16;
    width: 100%;

    @include media($mq-md) {
      width: 305px;
    }

    @include media($mq-lg) {
      width: 360px;
    }

    .holiday-title {
      margin-top: $space-16;
    }

    .hours-hr {
      border: 0;
      border-top: 1px solid map-get($colors, 'n1-200');
    }
    
    .hours-entry {
      display: flex;
      justify-content: space-between;
    }
  }
}

.location-details {
  .current-location-btn,
  .change-location-btn {
    margin: 0 0 $space-24 0;
    width: 100%;

    @include media($mq-md) {
      margin: 0 0 0 0;
    }
  }

  .current-location-btn {
    pointer-events: none;
  }
}

.location-faq {
  background-color: map-get($colors, 'n1-100');
  border-radius: 8px;
  margin: $space-40 calc($wrapper-padding * -1) 0;

  @include media($mq-md) {
    margin: $space-40 0 0;
  }

  .location-container {
    flex-direction: column;
    padding: $space-24 $wrapper-padding;
  }

  .faq-hr {
    color: map-get($colors, 'n1-200');
    margin: $space-24 0;
  }

  .faq-wrapper {
    display: grid;
    gap: $space-24;

    .faq-entry {
      display: grid;
      gap: $space-8;

      .faq-answer {
        white-space: pre-wrap;
      }
    }
  }
}
