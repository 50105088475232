.confirmation {
  @include media($mq-ltmd) {
    padding: 0;
  }

  .order-info {
    align-items: center;
    border-bottom: 1px solid map-get($colors, 'n1-200');
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $space-16 $space-24;

    @include media($mq-md) {
      border-bottom: 0;
      flex-direction: row;
      height: 183px;
      margin-bottom: $space-40;
      padding: 0;
    }
  }

  .order-details {
    text-align: center;

    @include media($mq-md) {
      padding-left: $space-40;
      text-align: left;
    }
  }

  .bullet {
    margin: 0 $space-8;
  }

  .bottom-row {
    display: flex;
    flex-direction: column;
    gap: $space-16;
    margin-bottom: $space-16;

    @include media($mq-md) {
      flex-direction: row;
      gap: $space-24;
      margin-bottom: 0;
    }
  }

  .store-info {
    align-items: center;
    border-bottom: 1px solid map-get($colors, 'n1-200');
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $space-16 $space-24;
    width: 100%;

    @include media($mq-md) {
      border: 1px solid map-get($colors, 'n1-200');
      border-radius: 8px;
      height: 327px;
      padding: $space-24;
    }
  }

  .logo-container {
    height: 60px;
    margin: $space-16 0;
    position: relative;
    width: 178px;
  }

  .store-logo {
    object-fit: contain;
  }

  .boxes {
    display: flex;
    flex-direction: column;
    gap: $space-16;
    width: 100%;

    @include media($mq-md) {
      gap: $space-24;
    }
  }

  .box {
    align-items: center;
    background-color: map-get($colors, 'n1-100');
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: $space-16;
    height: 100%;
    justify-content: center;
    padding: $space-24;
    text-align: center;
  }
}
