.customize-section {
  .file-upload-section {
    .subheading {
      display: inline-block;
      padding: $space-12 0 $space-16 0;
    }
  }

  .customization-tile.no-photo-tile {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;

    .no-photo-icon {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 $space-16;
      width: 50%;

      @include media($mq-md) {
        min-height: 96px;
      }

      @include media($mq-lg) {
        width: 40%;
      }
    }
  }

  .customization-tile.photo-upload-tile {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: $space-24 $space-16;
    text-align: center;

    @include media($mq-md) {
      flex-direction: row;
      padding: $space-16;
    }

    .upload-preview-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin: 0 $space-16;

      @include media($mq-md) {
        width: 50%;
      }

      @include media($mq-lg) {
        width: 40%;
      }

      img {
        cursor: pointer;
      }

      .upload-preview {
        max-width: 96px;
        min-height: 96px;
        padding: $space-24 0;
        position: relative !important;
      }
    }

    .upload-btn-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;

      .button-small {
        height: $space-48;
      }

      .add-photo-btn {
        font-size: rem(16px);
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;

        .add-photo-icon {
          margin-right: 10px;
          width: auto;
        }
      }

      .trash-btn {
        border-color: map-get($colors, 's2-500');
        border-radius: 60px;
        color: map-get($colors, 's2-500');
        margin-left: $space-12;
        padding: $space-20;
      }
    }
  }

  .body-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;

    @include media($mq-md) {
      width: 60%;
    }
  }
}
