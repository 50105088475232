$product-grid-spacing-mobile: $space-16;
$product-grid-spacing-desktop: $space-24;

.product-grid {
  background-color: map-get($colors, 'n1-100');
  margin-left: -$wrapper-padding;
  margin-right: -$wrapper-padding;
  margin-top: $space-8;
  padding: $product-grid-spacing-mobile;
  position: relative;

  @include media($mq-md) {
    border-radius: 8px;
    margin-left: 0;
    margin-right: 0;
    padding: $product-grid-spacing-desktop;
  }

  .headline {
    margin-bottom: $space-8;
  }

  .subhead {
    margin-bottom: $space-24;

    @include media($mq-md) {
      margin-bottom: $space-32;
    }
  }

  .grid-container {
    margin: $space-24 0 0 0;

    @include media($mq-md) {
      margin: $space-32 0 0 0;
    }
  }
}

// this is so the filters can be absolutely positioned and still respect parent padding
.product-grid-container {
  position: relative;
}

.product-card {
  background-color: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $product-grid-spacing-mobile;
  position: relative;
  transition: top 0.2s ease-out, box-shadow 0.2s ease-out;

  @include media($mq-md) {
    padding: $product-grid-spacing-desktop;

    &:hover {
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
      top: -2px;
    }
  }

  .image-container {
    height: 0;
    padding-top: calc(100% * (100 / 120));
    position: relative;
    width: 100%;
    
    .image {
      height: 100%;
      object-fit: cover;
      position: absolute !important;
    }
  }

  .name {
    color: map-get($colors, 'n1-500');
    margin: $space-16 0 $space-40 0;
  }

  .cta {
    align-items: center;
    bottom: $product-grid-spacing-mobile;
    color: map-get($colors, 'n1-300');
    display: flex;
    position: absolute;

    @include media($mq-md) {
      bottom: $product-grid-spacing-desktop;
    }
  }
}

.products-loading {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-right: $space-8;
  position: relative;
  right: 0;
  top: 0;
  transform: none;

  @include media($mq-md) {
    margin-right: $space-12;
  }
}
