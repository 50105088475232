.legal-container {
  overflow-y: auto;

  ol {
    padding-inline-start: $space-32;

    // for nested lists
    ol {
      margin: $space-24 0 0 0;
    }

    li {
      margin: 0 0 $space-24 0;
    }
  }

  p {
    margin: 0 0 $space-24;
  }
}

// ordered list: A.
.ol-upper-alpha {
  list-style-type: upper-alpha;
}

// ordered list: a.
.ol-lower-alpha {
  list-style-type: lower-alpha;
}

// ordered list: I.
.ol-upper-roman {
  list-style-type: upper-roman;
}

// ordered list: i.
.ol-lower-roman {
  list-style-type: lower-roman;
}

// ordered list: 1.
.ol-decimal {
  list-style-type: decimal;
}
