.intellectual-properties-container {
  h1 {
    margin-bottom: $space-24;
  }

  .table {
    display: flex;
    flex-direction: column;

    @include media($mq-md) {
      flex-direction: row;
    }
  }

  .column {
    flex: 50%;
    gap: $space-16;

    h4 {
      margin-bottom: $space-8;
    }

    p {
      margin-bottom: $space-16;
    }
  }
}
