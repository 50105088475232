/* stylelint-disable selector-class-pattern */

.mobile-nav-menu {
  overflow-y: visible !important;
  width: 90%;
}

// mui is fun...
.MuiDrawer-root {
  &.MuiDrawer-modal {
    &.MuiModal-root {
      z-index: $zi-mobile-drawer;
    }
  }
}

.mobile-nav-close-button {
  position: absolute;
  right: rem(-16px);
  top: 50%;
  transform: translateY(-50%);

  path {
    &:first-child {
      fill: map-get($colors, 'n1-500');
    }

    &:last-child {
      fill: map-get($colors, 'white');
    }
  }
}

.mobile-nav-menu-title {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: $space-24;
  position: relative;

  .button-title {
    color: map-get($colors, 'white');
    display: flex;

    .typography {
      font-size: 22px;
    }
  }

  .svg-chevron-left {
    margin-top: 4px;
  }

  .find-more-stores {
    align-items: center;
    background-color: map-get($colors, 'n1-500');
    border: 0;
    border-radius: 500px;
    color: map-get($colors, 'white');
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    outline-offset: 4px;
    padding: 1rem 4.375rem;
  }
}

.mobile-nav-menu-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $space-24 $space-24 0 $space-24;

  .location-title {
    display: flex;
  }

  span {
    display: block;
  }

  button {
    max-width: 100%;
    text-wrap: nowrap;
    
    &.find-more-stores {
      background-color: map-get($colors, 'n1-500');
      border: 0;
      border-radius: 500px;
      color: map-get($colors, 'white');
      cursor: pointer;
      font-size: rem(16px);
      font-weight: 500;
      line-height: 1;
      outline-offset: 4px;
      padding: 1rem 4.375rem;
    }
  }
}

.mobile-nav-category-item {
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: $space-40;
  margin: 0 0 $space-16 0;
}

.mobile-nav-category-link {
  color: map-get($colors, 'n1-500');
  display: block;
  padding: $space-16 $space-24;
  text-align: left;
  width: 100%;
}

.mobile-nav-logo {
  margin: auto auto $space-24 auto;
}

.mobile-nav-logo-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  filter: grayscale(1);
  height: 45px;
  min-width: 125px;
  opacity: 0.6;
}
