.map-styles {
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: 8px;
  height: 400px;
  width: 100%;

  @include media($mq-md) {
    height: 640px;
  }
}

.map-container {
  position: relative;
}

.map-container-overlay {
  background-color: map-get($colors, 'transparent');
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 0.2s ease-out;
  z-index: 1;

  &.loading {
    background-color: rgba(map-get($colors, 'white'), 0.5);
    pointer-events: auto;
  }
}
