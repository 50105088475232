// ordered list: a)
.ol-lower-alpha-paren {
  counter-reset: list;

  > li {
    list-style: none;
    position: relative;

    &:before {
      content: counter(list, lower-alpha) ') ';
      counter-increment: list;
      left: rem(-20px);
      position: absolute;
    }
  }
}
