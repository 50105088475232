.customizer-text-input-wrapper {
  background-color: map-get($colors, 'n1-100');
  display: block;
  padding: $space-32 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: $zi-customizer-text-input-wrapper;

  @include media($mq-md) {
    padding: $space-32 $space-40;
  }

  button {
    margin: 2px;
    padding: 4px;
  }

  textarea {
    border: 1px solid map-get($colors, 'n1-200');
    margin-top: $space-8;
    padding: 8px;
    resize: none;
    width: 100%;
  }

  path {
    transition: all 0.2s ease-out;
  }
}

.cte-close {
  background-color: map-get($colors, 's2-500');
  padding: 2px 5px;
}

.font-selector {
  display: block;

  .fs-button {
    align-items: flex-start;
    background-color: initial;
    border: 0 none;
    font-size: 1.5rem;
    margin: 0 0 $space-16 0;
    padding: $space-4;
    transition-duration: 0.2s;
    transition-property: background-color, color;
    transition-timing-function: ease-out;

    &:hover {
      background-color: map-get($colors, 'n1-200');
      color: map-get($colors, 'black');
    }
  }
}

.color-selector {
  .color-grid-item {
    padding: $space-8;
  }

  .cs-button {
    border: 1px solid map-get($colors, 'n1-300');
    border-radius: 100%;
    height: 28px;
    width: 28px;

    &.selected {
      border: 2px solid map-get($colors, 'n1-400');
    }
  }
}

// editing text state
.customizer-text-input-toolbar {
  display: flex;

  button {
    background-color: initial;
    border: 0 none;
    border-right: 2px solid map-get($colors, 'white');

    &:last-of-type {
      border-right: 0 none;
    }

    &:hover {
      background-color: initial;
      color: map-get($colors, 'black');

      path {
        fill: map-get($colors, 'black');
        stroke: map-get($colors, 'transparent');
      }
    }
  }
}

.editor-text-input {
  font-size: rem(24px);
}
