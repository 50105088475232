// Home page styles
.home {
  .brand-image-container {
    margin-bottom: $space-24;

    @include media($mq-md) {
      margin-bottom: $space-40;
    }

    &.desktop {
      display: none;

      @include media($mq-md) {
        display: block;
      }
    }

    &.mobile {
      @include media($mq-md) {
        display: none;
      }
    }
  }

  .brand-banner {
    border-radius: 8px;
  }

  .welcome-section {
    display: flex;
    flex-direction: column;
    gap: $space-24;
    margin-bottom: $space-24;

    @include media($mq-md) {
      flex-direction: row;
      gap: $space-32;
      margin-bottom: $space-40;
    }
  }

  .welcome-box {
    border-radius: 8px;
    padding: $space-24;
  }

  .full-width {
    width: 100%;
  }

  .box-label {
    color: map-get($colors, 'n1-700');
  }

  .box-title {
    margin-bottom: $space-8;
  }

  .welcome-message {
    border: 1px solid map-get($colors, 'n1-200');
    flex: 1;

    a {
      color: inherit;
      text-decoration: underline;
      transition: 0.2s ease-out;
    
      &:hover {
        color: map-get($colors, 'n1-300');
      }
    }
  }

  .store-address {
    background-color: map-get($colors, 'n1-100');

    @include media($mq-md) {
      &:not(.full-width) {
        width: 350px;
      }
    }
  }
}
