.kiosk-photo-modal {
  text-align: center;

  .headline {
    margin-bottom: $space-16;
  }

  .subhead {
    margin-bottom: $space-32;
  }

  .hr {
    background-color: map-get($colors, 'n1-200');
    height: 1px;
    margin-bottom: $space-24;
    width: 100%;
  }
}

.qr-wrapper {
  display: flex;
}

.qr-code-desktop,
.qr-code-mobile {
  padding: $space-12;
}

.qr-code-desktop {
  display: none;

  @include media($mq-md) {
    display: block;
  }
}

.qr-code-mobile {
  @include media($mq-md) {
    display: none;
  }
}

.qr-code-image {
  height: 216px;
  width: 216px;
}

.qr-copy {
  @include media($mq-md) {
    padding-left: $space-40;
  }
}

.waiting-text {
  align-items: center;
  display: flex;
  justify-content: center;
}

.waiting-indicator-wrapper {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-right: $space-16;

  .circle-loading-indicator {
    display: inline-block;
    height: 24px;
    width: 24px;
  }
}
