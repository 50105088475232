.customization-editor-wrapper {
  margin: 0 auto;
  max-width: 768px;
  position: relative;
  width: 100%;

  @include media($mq-editor) {
    padding: 0;
  }

  &.hidden {
    display: none;
  }

  .headline {
    display: none;

    @include media($mq-md) {
      display: block;
      margin: 0 0 $space-24 0;
    }
  }
}

.customization-controls-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: $zi-0;

  .controls-row {
    align-items: center;
    background-color: map-get($colors, 'white');
    display: flex;
    flex-direction: row;
    height: 88px;
    justify-content: space-evenly;
    margin: 0 0 -1px 0;
    transition: all 0.2s ease-out;

    &.left {
      justify-content: left;
    }
  }

  .collapsed {
    height: 0;

    button {
      opacity: 0;
    }
  }

  button {
    align-items: center;
    background-color: map-get($colors, 'white');
    border: 1px solid map-get($colors, 'n1-200');
    color: map-get($colors, 'n1-400');
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0 0 0 -1px;
    width: 100%;
    word-break: break-word;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;

      &:hover {
        background-color: initial;
        color: initial;

        svg {
          fill: initial;
        }
      }
    }

    .typography {
      font-size: rem(13px);

      @include media($mq-md) {
        font-size: 1rem;
      }
    }

    svg {
      fill: map-get($colors, 'n1-400');
      height: 32px;
      margin: 0 0 5px 0;
      width: 32px;

      @include media($mq-md) {
        height: 36px;
        width: 36px;
      }
    }

    // this is for the not-editing stage
    &:hover {
      background-color: #FF8871; // from DecoPac
      color: map-get($colors, 'white');

      svg {
        fill: map-get($colors, 'white');
        stroke: map-get($colors, 'white');
      }

      path {
        stroke: map-get($colors, 'transparent');
      }
    }
  }

  .quarter-button {
    max-width: 25.2%; // magic
    overflow: hidden;

    &.dont-shrink {
      flex-shrink: 0;
      width: 25.2%; // magic
    }

    &.selected {
      outline: 3px dashed map-get($colors, 'n1-500');
      outline-offset: -4px;
    }
  }

  .red-button {
    svg {
      fill: #FF8871; // deom DecoPac
    }
  }

  .gray {
    background-color: map-get($colors, 'n1-100');
  }

  .user-text {
    background-color: hsla(26, 26%, 95%, 0.4); // from DecoPac

    &:hover {
      background-color: initial;
      color: initial;
    }
  }

  .copy-button {
    pointer-events: none;
  }
}

// not sure if this has bad implications.
// it's to solve a re-init bug
.canvas-container {
  &:not(:last-child) {
    display: none;
  }
}

.customization-canvas-overlay {
  background-color: hsla(0, 0%, 100%, 0.75); // from DecoPac
  bottom: 0;
  display: flex;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.15s ease;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.done-button,
.edit-button {
  &:hover {
    path {
      fill: map-get($colors, 'white');
    }
  }
}

.temp-input {
  background: none;
  border: 0 none;
  color: map-get($colors, 'n1-500');
  font-size: rem(13px);
  overflow: hidden;
  padding: $space-8;
  pointer-events: none;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &:focus-visible {
    outline: 0 none;
  }
}

.customization-editor {
  background-color: map-get($colors, 'n1-200');
  border-radius: 4px;
  position: relative;
}
