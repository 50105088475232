// ---------------------------------------------------------
/*
    Classes to add bottom or top margin
*/
// ---------------------------------------------------------

.margin-top-4 {
  margin-top: $space-4;
}

.margin-top-8 {
  margin-top: $space-8;
}

.margin-top-12 {
  margin-top: $space-12;
}

.margin-top-16 {
  margin-top: $space-16;
}

.margin-top-24 {
  margin-top: $space-24;
}

.margin-top-32 {
  margin-top: $space-32;
}

.margin-top-40 {
  margin-top: $space-40;
}

.margin-top-44 {
  margin-top: $space-44;
}

.margin-top-48 {
  margin-top: $space-48;
}

.margin-top-56 {
  margin-top: $space-56;
}

.margin-top-64 {
  margin-top: $space-64;
}

.margin-top-80 {
  margin-top: $space-80;
}

.margin-bottom-4 {
  margin-bottom: $space-4;
}

.margin-bottom-8 {
  margin-bottom: $space-8;
}

.margin-bottom-12 {
  margin-bottom: $space-12;
}

.margin-bottom-16 {
  margin-bottom: $space-16;
}

.margin-bottom-24 {
  margin-bottom: $space-24;
}

.margin-bottom-32 {
  margin-bottom: $space-32;
}

.margin-bottom-40 {
  margin-bottom: $space-40;
}

.margin-bottom-48 {
  margin-bottom: $space-48;
}

.margin-bottom-56 {
  margin-bottom: $space-56;
}

.margin-bottom-64 {
  margin-bottom: $space-64;
}

.margin-bottom-80 {
  margin-bottom: $space-80;
}
