// Variables
// ------------------------------------------------------------------
$padding-large: rem(16px) rem(70px);
$padding-large-wide: rem(18px) rem(64px) rem(21px) rem(64px);
$padding-small: rem(10.5px) rem(36px);
$padding-nav: rem(12px);
$margin-icon: rem(5px);

// Default
// ------------------------------------------------------------------
button {
  background-color: transparent;
  border: 0;

  &:disabled {
    justify-content: center;
  }
}

.btn-link {
  > * {
    pointer-events: none;
  }

  &.button-dark {
    width: fit-content;
  }
}

// Primary
// ------------------------------------------------------------------
.button-primary-face {
  align-items: center;
  background-color: map-get($colors, 'p2-300');
  border-radius: 500px;
  display: flex;
  justify-content: center;
  padding: $padding-large;
  transform: translateX(3px) translateY(-7px);
  transition: background-color 0.2s ease-out;
  width: 100%;

  > * {
    pointer-events: none;
  }

  &:active {
    transform: translateX(2px) translateY(-5px);
  }

  &:hover {
    background-color: map-get($colors, 'p2-200');
  }
}

// fix for button elements to animate (not anchor elements)
.button-primary {
  &:active {
    .button-primary-face {
      transform: translateX(2px) translateY(-5px);
    }
  }
}

.button-primary {
  align-items: center;
  background-color: map-get($colors, 'p2-300');
  border-radius: 500px;
  display: flex;
  padding: $padding-large;
}

.button-primary {
  background-color: map-get($colors, 'p2-500');
  border-radius: 500px;
  color: map-get($colors, 'n1-500');
  cursor: pointer;
  font-size: rem(17px);
  font-weight: 700;
  line-height: 1;
  padding: 0;
  transition: transform 0.2s ease-out;

  &.button-small {
    align-items: center;
    background-color: map-get($colors, 'p2-300');
    border: 1px solid map-get($colors, 'p2-300');
    display: flex;
    justify-content: center;
    padding: $padding-small;
    transition: 0.2s ease-out;

    &:hover {
      background-color: map-get($colors, 'p2-200');
      border: 1px solid map-get($colors, 'p2-500');
    }
  }

  &.button-dark {
    background-color: map-get($colors, 'n1-200');
    color: map-get($colors, 'white');

    .svg {
      color: map-get($colors, 'white');
    }

    .button-primary-face {
      background-color: map-get($colors, 'n1-500');
    }

    &:hover .button-primary-face {
      background-color: map-get($colors, 'n1-500');
    }

    &:disabled {
      background-color: map-get($colors, 'n1-200');
      cursor: not-allowed;
      display: flex;
      padding: rem(18px) $space-64 rem(21px) $space-64;
    }
  }

  &.button-white {
    background-color: map-get($colors, 'n1-500');
    color: map-get($colors, 'n1-500');

    .button-primary-face {
      background-color: map-get($colors, 'white');
    }

    .svg {
      color: map-get($colors, 'n1-500');
    }
  }

  &.button-wide {
    padding: $padding-large-wide;
  }

  &.button-xlarge {
    font-weight: bold;

    @include media($mq-md) {
      font-size: rem(24px);
    }
  }
}

// Secondary
// ------------------------------------------------------------------
.button-secondary {
  align-items: center;
  background-color: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'n1-500');
  border-radius: 500px;
  color: map-get($colors, 'n1-500');
  cursor: pointer;
  display: flex;
  font-size: rem(16px);
  font-weight: 500;
  height: rem(56px);
  justify-content: center;
  line-height: 1;
  padding: $padding-large;
  transition: 0.2s ease-out;

  &.button-large {
    font-size: rem(20px);
    font-weight: 700;
  }

  &.button-small {
    padding: $padding-small;
  }

  &.button-wide {
    padding: $padding-large-wide;
  }

  &.button-nav {
    border-color: map-get($colors, 'p2-500');
    padding: $padding-nav;
  }

  &:disabled {
    border: 1px solid map-get($colors, 'n1-200');
    color: map-get($colors, 'white');
    cursor: not-allowed;

    &:hover {
      background-color: map-get($colors, 'transparent');
    }
  }
}

// Tertiary
// ------------------------------------------------------------------
.button-tertiary {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  font-size: rem(17px);
  font-weight: 500;
  line-height: 1;
  transition: 0.2s ease-out;

  svg path {
    transition: 0.2s ease-out;
  }

  // uncomment this is we want to change the default outline when tabbing through
  // &:focus {
  //   outline: none;
  //   box-shadow: 0 0 0 2pt white;
  // }

  &:hover {
    color: map-get($colors, 'p2-500');

    svg path {
      fill: map-get($colors, 'p2-500');
    }
  }
}

// Dark
// ------------------------------------------------------------------
.button-dark:not(.button-primary) {
  align-items: center;
  background-color: map-get($colors, 'n1-500');
  border: 0;
  border-radius: 500px;
  color: map-get($colors, 'white');
  cursor: pointer;
  display: flex;
  font-size: rem(16px);
  font-weight: 700;
  line-height: 1;
  outline-offset: 4px;
  padding: $padding-large;

  &:disabled {
    background-color: map-get($colors, 'n1-200');
    cursor: not-allowed;
  }

  &.button-large {
    font-size: rem(20px);
    font-weight: 700;
  }

  &.button-small {
    padding: $padding-small;
  }

  &.button-wide {
    padding: $padding-large-wide;
  }
}

// Common
// ------------------------------------------------------------------
.button-icon-left {
  .svg {
    margin-right: $margin-icon;
  }
}

.button-icon-right {
  .svg {
    margin-left: $margin-icon;
  }
}

button.button-text-link {
  color: map-get($colors, p3-500);
  text-decoration: underline;

  &.dark-bold {
    color: map-get($colors, n1-500);
    font-weight: bold;
  }
}

.button-load-more {
  margin-bottom: $space-48;

  @include media($mq-md) {
    margin-bottom: $space-72;
  }
}

.button-group-inline {
  display: flex;
  flex-direction: column;
  margin: 0 0 1.5rem 0;

  @include media($mq-md) {
    flex-direction: row;
  }

  a {
    margin: 0 0 1rem 0;

    @include media($mq-md) {
      margin: 0 1rem 0 0;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.customize-icon {
  flex-shrink: 0;
}
