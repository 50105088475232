.location-form-wrapper {
  margin: 0 0 16px 0;
}

.location-form {
  display: flex;
  flex-direction: column;

  @include media($mq-md) {
    flex-direction: row;
  }
}

.location-radius-wrapper {
  @include media($mq-md) {
    margin: 0 16px 0 0;
  }
}

.location-radius-picker {
  background-color: map-get($colors, 'white');
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M8 9.394 11.63 6l1.037.97L8 11.333 3.333 6.97 4.37 6 8 9.394z' fill='black' /></svg>");
  background-position-x: 96%;
  background-position-y: 12px;
  background-repeat: no-repeat;
  background-size: 24px;
  border: 1px solid map-get($colors, 'n1-500');
  border-radius: 8px;
  height: 48px;
  margin: 1rem 0;
  padding: 0 24px 0 16px;
  width: 100%;

  @include media($mq-md) {
    width: 180px;
  }
}

.location-form-btn {
  height: 48px;
  margin: 1rem 0;

  @include media($mq-md) {
    width: 103px;
  }
}
