.modal.footer-modal {
  height: 100%;

  a {
    text-decoration: underline;
    transition: 0.2s ease-out;

    &:hover {
      color: map-get($colors, 'n1-300');
    }
  }

  .loading {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
}
