.category-grid {
  background-color: map-get($colors, 'n1-100');
  margin-left: -$wrapper-padding;
  margin-right: -$wrapper-padding;
  padding: $space-24;

  @include media($mq-md) {
    border-radius: 8px;
    margin-left: 0;
    margin-right: 0;
  }

  .subhead {
    margin: $space-8 0 0 0;
  }

  .pagination {
    margin-top: $space-32;
  }
}

.category-grid-header {
  margin: 0 0 $space-24 0;

  @include media($mq-md) {
    margin: 0 0 $space-32 0;
  }
}

.category-card {
  background-color: var(--primary-color, map-get($colors, 'n1-500'));
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: top 0.2s ease-out, box-shadow 0.2s ease-out;

  &:hover {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
    top: -2px;
  }

  // Disable clicking of internal elements for analytics purposes
  > * {
    pointer-events: none;
  }

  .name {
    align-items: center;
    color: map-get($colors, 'white');
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: $space-8 $space-12;
    text-align: center;
  }

  .image-container {
    background-color: map-get($colors, 'white');
    height: 0;
    padding-top: calc(100% * (100 / 120));
    position: relative;
    width: 100%;
    
    .image {
      height: 100%;
      object-fit: cover;
      padding: 0 $space-8;
      position: absolute !important;
    }
  }

  // NOTE: old, but keeping for posterity
  // .image-wrapper {
  //   background-color: map-get($colors, 'white');
  //   height: 185px;
  //   padding: $space-24 $space-8;
  //   position: relative;

  //   @include media($mq-lg) {
  //     max-height: 185px;
  //   }

  //   .image {
  //     height: 100%;
  //     object-fit: contain;
  //     width: 100%;
  //   }
  // }

  .see-all {
    align-items: center;
    background-color: map-get($colors, 'white');
    color: map-get($colors, 'n1-300');
    display: flex;
    justify-content: center;
    padding-bottom: $space-8;
  }
}
