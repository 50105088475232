// ---------------------------------------------------------
/*
    examples
*/
// ---------------------------------------------------------

$wrapper-structure: rem(1440px);
$wrapper-content: rem(100px);

/* THIS VARIABLE SHOULD ONLY BE USED FOR THE WRAPPER */
/* 48px accounts for 24px padding on each side so the resulting useable space is 1130px */
$max-container: calc(1130px + 48px);
$wrapper-padding: $space-24;
