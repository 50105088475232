// product detail page styles
.pdp-carousel {
  position: relative;
}

.pdp-pagination {
  text-align: center;

  .swiper-pagination-bullet {
    background: map-get($colors, 'n1-200');
    opacity: 1;

    &:focus {
      outline: 1px solid map-get($colors, 'white');
    }
  }

  .swiper-pagination-bullet-active {
    background: map-get($colors, 'n1-500');
  }
}

.pdp-details {
  background-color: map-get($colors, 'n1-100');
  margin-left: -$wrapper-padding;
  margin-right: -$wrapper-padding;
  padding: $space-24;

  @include media($mq-md) {
    border-radius: 8px;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: $space-48;
  }
}

.pdp-headline {
  line-height: 1.4;
  margin: 0 0 $space-24 0;
}

.pdp-description {
  line-height: $space-24;
  margin: 0 0 $space-24 0;
}

.potential-delivery-date {
  background-color: map-get($colors, 'white');
  border-radius: 4px;
  color: map-get($colors, 'n1-500');
  margin: 0 0 $space-32 0;
  padding: $space-8;
  text-align: center;

  // Wrapper for call to order text
  .cto-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;

    .cto-text {
      margin-left: $space-8;
    }
  }

  &.call-to-order {
    margin: 0 0 $space-16 0;

    .cto-phone-link {
      display: inline;
    }

    .cto-phone-link:hover {
      text-decoration: underline;
    }
  }
}

button.product-detail-customize-btn {
  margin-bottom: $space-32;
  width: 100%;

  @include media($mq-md) {
    display: block;
  }

  .button-primary-face {
    justify-content: center;
    padding: $space-16 0;
    width: 100%;
  }
}

.print-order-form-btn-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;


  .print-order-form-btn {
    background-color: transparent;
    font-weight: 400;
    height: $space-40;
    margin-bottom: $space-32;
    padding: $space-8 $space-16;
  }
}


.pdp-copyright {
  display: flex;
  flex-direction: column;
  padding: 0 0 $space-8 0;
}

.pdp-product-id {
  margin: 0 0 $space-8 0;
  text-transform: capitalize;
}

.pdp-trademark {
  text-align: left;
}

.pdp-cta {
  margin: 0 0 $space-24 0;
}

.pdp-retailer {
  margin: 0 0 $space-24 0;
}
