.location-info-window {
  padding: 12px 0 12px 12px;

  .button {
    &.location-cta {
      font-size: rem(16px);
      height: $space-40;
      width: 100%;
    }

    &.location-cta-current {
      background-color: map-get($colors, 'white');
      border: 1px solid map-get($colors, 'n1-300');
      color: map-get($colors, 'n1-300');
      pointer-events: none;
    }
  }
}

.locations-mobile {
  .location-info-window {
    .button {
      &.location-cta {
        font-size: rem(14px);
        padding: 0;
      }
    }
  }
}

.location-title {
  align-items: center;
  color: map-get($colors, 'n1-500');
  display: inline-flex;
  font-size: rem(18px);
  font-weight: 500;
  text-decoration: none;
}

.location-info-window-body {
  font-size: rem(14px);
  margin: 16px 0;
}

// override google styles
// ------------------------------------------------------------------
.gm-style {
  // info window
  .gm-style-iw-c {
    border: 1px solid map-get($colors, 'n1-200');
    box-shadow: 0 0 8px 0 rgba(map-get($colors, 'black'), 0.25);
    width: 250px;
  }
}

.gm-style-iw {
  // hide "close" button
  button {
    &.gm-ui-hover-effect {
      display: none !important;
    }
  }
}
