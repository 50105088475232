.ot-form-wrapper {
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: 8px;
  margin: auto;
  max-width: 750px;
}

.ot-form-wrapper iframe {
  border: 0;
  border-radius: 8px;
  height: 1250px;
  width: 100%;
}
