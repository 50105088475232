
.customize {
  .background-tile {
    height: auto;

    .background-tile-warning {
      color: map-get($colors, 'background-tile-warning');
    }
  }

  .customize-section {
    position: relative;

    .piped-message {
      color: map-get($colors, 'n1-500');
      display: none;
      margin-top: $space-20;
      resize: none;
    }

    .piped-message-color-heading,
    .piped-message-clear-btn {
      display: none;
    }
  }
}

.piped-message-section.section-header {
  display: flex;
  justify-content: space-between;

  .piped-message-header {
    padding-right: $space-4;
  }
}

.customize-section.active {
  .options-grid.color-selector {
    &.piped-message-colors {
      margin-top: 0;
    }
  }

  .piped-message {
    display: flex;
  }

  .piped-message-clear-btn {
    background-color: map-get($colors, 'white');
    border: 1px solid map-get($colors, 'n1-300');
    border-radius: 24px;
    display: flex;
    padding: 1px $space-12;

    span {
      color: map-get($colors, 'n1-500');
    }

    &:hover {
      border: 1px solid map-get($colors, 'n1-500');
    }

    &.disabled {
      border: 1px solid map-get($colors, 'n1-300');

      span {
        color: map-get($colors, 'n1-300');
      }
    }
  }

  .piped-message-color-heading {
    align-items: center;
    display: flex;
    margin: $space-16 0;

    &.disabled {
      color: map-get($colors, 'n1-300');
    }
  }

  &.error {
    outline: 2px solid map-get($colors, 's2-500');
    outline-offset: -2px;

    .error-message {
      color: map-get($colors, 's2-500');
      margin-top: $space-8;
    }
  }
}
