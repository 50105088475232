.pagination {
  margin-bottom: 0;
  margin-left: -$wrapper-padding;
  margin-right: -$wrapper-padding;
  margin-top: $space-24;

  @include media($mq-md) {
    margin: $space-24 0 0 0;
  }
}

.pagination-nav {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pagination-load {
  display: flex;
  justify-content: center;
  margin: $space-24 0 0 0;
}

.pagination-more-or-less-btn {
  &.button-secondary {
    background-color: map-get($colors, 'white');
    border-color: map-get($colors, 'n1-500');
    color: map-get($colors, 'n1-500');
    font-size: rem(15px);
    height: unset;
    padding: $space-8 $space-16 rem(10px) $space-16;
  }
}

.pagination-chev {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;

  a {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    line-height: 1;
    width: 100%;

    path {
      transition: 0.2s ease-out;
    }

    &:hover {
      path {
        fill: map-get($colors, 'n1-500');
      }
    }
  }

  svg {
    height: 24px;
    width: 14px;
  }
}

.pagination-chev-prev {
  margin: 0 $space-4 0 0;
}

.pagination-chev-next {
  margin: 0 0 0 $space-4;
}

.pagination-chev-disabled {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  line-height: 1;
  opacity: 0.2;
  pointer-events: none;
  width: 100%;
}

.pagination-item {
  margin: 0 $space-4;

  .typography {
    color: map-get($colors, 'n1-500');
    line-height: 1;
    transition: 0.2s ease-out;
  }

  &:hover {
    .typography {
      color: map-get($colors, 'n1-500');
    }
  }
}

.pagination-link {
  align-items: center;
  background-color: map-get($colors, 'transparent');
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  text-decoration: none;
  width: 40px;

  &.pagination-link-selected {
    background-color: map-get($colors, 'n1-500');
    pointer-events: none;

    .typography {
      color: map-get($colors, 'white');
    }
  }
}

// Makes the scroll-to functionality work on mobile with the sticky elements
.cakes-anchor {
  position: relative;
  top: -55px;

  @include media($mq-md) {
    top: 1px;
  }
}
