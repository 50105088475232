.add-ons-page-wrapper {
  // Allows the normally padded containers to be full width on mobile
  padding-top: 0 !important;

  @include media($mq-ltmd) {
    padding: 0 !important;
  }
}

.add-ons {
  .headline {
    margin: $space-24 0 $space-24 $wrapper-padding;

    @include media($mq-md) {
      margin: 0 0 $space-24 0;
    }
  }
}
