/* stylelint-disable selector-class-pattern */

// Checkout page styles
.checkout {
  // background color for page
  background-color: map-get($colors, 'n1-100');

  @include media($mq-ltmd) {
    .wrapper {
      padding: 0;
    }

    .checkout-section {
      border-radius: 0;
    }
  }

  .checkout-preview-image-container {
    margin: 0 0 $space-16 0;
  }

  .image-container {
    height: 100%;
    min-width: 240px;
    padding-top: calc(100% * (100 / 120));
    position: relative;
  }

  .checkout-preview {
    height: 100%;
    margin-top: -50px;
    max-width: 240px;
    position: relative !important;

    @include media($mq-sm) {
      max-width: 280px;
    }

    @include media($mq-md) {
      margin-top: -40px;
      max-width: 100%;
    }

    &.custom {
      margin: 0 0 $space-8 0;
    }

    &.cupcake,
    &.decoset,
    &.preview-layered.enhanced,
    &.preview-standard.enhanced {
      margin-top: -10px;
    }

    &.preview-standard.round,
    &.decoset.round {
      margin: 20px 0;
    }
  }

  // highlight on focus
  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: map-get($colors, 'blue-textfield-default');
      border-width: 2px;
    }
  }

  // update font family for mui typography
  .MuiTypography-root {
    font-family: $font-family;
  }

  .MuiCheckbox-root {
    border: 2px solid transparent;
    border-radius: 6px;
    margin: 6px;
    padding: 0;

    &.Mui-checked {
      color: map-get($colors, 'n1-500');
    }

    &.Mui-focusVisible {
      border: 2px solid map-get($colors, 'black');
    }
  }

  // .MuiCheckbox-root {
  //    border-radius: 6px;
  //    padding: 0;
  //    border: 2px solid transparent;
  //    margin: 6px;

  //    &.Mui-focusVisible {
  //       border: 2px solid black;
  //    }
  // }

  // set font for form labels
  .MuiFormControlLabel-label {
    font-size: rem(16px);
    font-weight: 700;
  }

  // make inputs 100% width
  .MuiFormControl-root {
    width: 100%;
  }

  // for some reason the border radius is
  // set here but it's inherited later
  // where the border is actually set
  .MuiInputBase-root {
    border-radius: 8px;
    width: 100%;
  }

  // make it 48px tall, etc
  .MuiInputBase-input {
    color: map-get($colors, 'n1-500');
    font-family: $font-family;
    font-size: rem(17px);
    height: rem(48px);
    padding: 0 24px;
  }

  // set the border color
  .MuiOutlinedInput-notchedOutline {
    border-color: map-get($colors, 'n1-300');
  }

  // remove hover from icon
  .MuiButtonBase-root {
    &:hover {
      background-color: initial;
    }
  }

  // remove "ripple" animation on click
  .MuiTouchRipple-root {
    display: none;
  }
}

.checkout-section {
  background-color: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: 8px;
  margin: 0 0 $space-24 0;
  padding: $space-24;

  .tax-copy {
    text-align: right;
  }
}

.lg-space {
  margin: 0 0 $space-24 0 !important;
}

.sm-space {
  margin: 0 0 $space-16 0;
}

.xs-space {
  margin: 0 0 $space-8 0;
}

.checkout-receipt-item {
  margin: 0 0 $space-8 0;
}

.checkout-receipt-item-name {
  .typography {
    color: map-get($colors, 'n1-300');
  }
}

.checkout-receipt-item-details {
  display: flex;
  justify-content: space-between;
  margin: 0 0 $space-8 0;
  padding-left: $space-8;

  .typography-small-medium {
    padding-right: $space-8;
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
  }
}

.checkout-receipt-item-details:last-child {
  padding-left: $space-8;
}

.borders-label,
.frosting-label {
  margin-right: $space-8;
}

.view-photo-upload-btn {
  margin-left: $space-8;
}

.borders-container {
  display: inline-block;
}

.checkout-receipt-hr {
  background-color: map-get($colors, 'n1-200');
  border: 0 none;
  height: 1px;
  margin: $space-16 0;
}

.checkout-receipt-subtotal {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.checkout-image-column {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;

  @include media($mq-md) {
    position: relative;
  }

  .button {
    height: 32px;
    margin: 0 auto;
    padding: 0 $space-24;
  }

  .note {
    color: map-get($colors, 'n1-400');
    line-height: rem(18px);
    margin: 0 0 $space-16 0;
    text-align: center;
  }
}

.payWrapper {
  padding: 0;
}

.pay-container {
  position: sticky;
  top: $space-24;
}

.pay-container-title {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0 $space-24 0;
  position: relative;
}

.pay-title {
  background-color: map-get($colors, 'white');
  padding: 0 $space-16;
  z-index: $zi-pay-title;
}

.pay-hr {
  background-color: map-get($colors, 'n1-200');
  border: 0 none;
  height: 1px;
  position: absolute;
  top: 50%;
  width: 100%;
}

.checkout-order-btn {
  height: rem(49px);
  width: 100%;

  &.loading {
    &:disabled {
      background-color: map-get($colors, 'n1-500');
      height: rem(49px);
    }
  }
}

// .date-picker {
//     // NOTE: might use later
//     // override selected button background color
//     .MuiButtonBase-root {
//       &.Mui-selected {
//         background-color: var(--primary-color, map-get($colors, 'n1-500')) !important;
//       }
//     }
// }

.pickup-info {
  .checkout-receipt-hr {
    margin: $space-24 0;
  }
}

.pickup-time-wrapper {
  position: relative;
}

.time-picker {
  appearance: none;
  background-color: transparent;
  border: 1px solid map-get($colors, 'n1-300');
  border-radius: 8px;
  color: map-get($colors, 'n1-500');
  font-family: $font-family;
  font-size: rem(17px);
  height: rem(48px);
  padding: 10px 24px;
  width: 100%;

  &:focus-visible {
    border: 2px solid map-get($colors, 'n1-500');
    outline: 0;
  }
}

.time-icon {
  align-items: center;
  display: flex;
  height: 20px;
  pointer-events: none;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
}

// disallow users from manually entering date
.MuiPickersToolbar-penIconButton {
  display: none;
}

.pickup-method-option {
  display: flex;
}

.pickup-fake-radio {
  margin: 0 $space-8 0 0;
}

.checkbox-details {
  margin: 0 0 0 $space-32;

  &:first-of-type {
    margin: 0 0 $space-24 $space-32;
  }
}

.opt-in-choices {
  a {
    text-decoration: underline;
  }
}

// inputs with an error
.form-error {
  .MuiOutlinedInput-notchedOutline {
    border-color: map-get($colors, 's2-500');
    border-width: 2px;
  }

  .MuiInputBase-root {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: map-get($colors, 's2-500');
        border-width: 2px;
      }
    }
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: map-get($colors, 's2-500');
      border-width: 2px;
    }
  }

  &.time-picker {
    border-color: map-get($colors, 's2-500');
    border-width: 2px;
  }

  &:focus-visible {
    border-color: map-get($colors, 's2-500');
    border-width: 2px;
    outline-color: map-get($colors, 's2-500');
  }
}

// MUI calls toast a snackbar
.MuiSnackbar-root {
  width: 100%;

  .MuiPaper-root {
    background-color: map-get($colors, 's2-500');
    width: calc(100% - ($wrapper-padding * 2));
  }
}

.MuiSnackbarContent-message {
  flex-grow: 1;
  text-align: center;

  @include media($mq-md) {
    // close button is 46px wide
    margin: 0 0 0 46px;
  }
}

// error/helper text
.MuiFormHelperText-root {
  &.Mui-error {
    color: map-get($colors, 's2-500');
    font-size: rem(14px);
    margin: $space-8 0 0 0;
  }
}

.MuiInputBase-root:-webkit-autofill {
  background-color: transparent;
}
