/* stylelint-disable selector-class-pattern */

.filters-container {
  background-color: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: 8px;
  margin-top: $space-8;
  width: 100%;
  z-index: $zi-filters-menu;

  @include media($mq-md) {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 240px;

    &.open {
      border-color: map-get($colors, 'n1-200');
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    }

    &:hover:not(.open) {
      border-color: map-get($colors, 'n1-300');
    }
  }

  &.open {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  }
}

.filters-button {
  align-items: center;
  color: map-get($colors, 'n1-400');
  display: flex;
  gap: $space-16;
  justify-content: space-between;
  padding: $space-8 $space-24;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
}

.filters-label-wrapper {
  align-items: center;
  display: flex;
}

.filters-count {
  align-items: center;
  background-color: map-get($colors, 'n1-500');
  border-radius: 50%;
  color: map-get($colors, 'white');
  display: flex;
  height: 20px;
  justify-content: center;
  margin-left: $space-8;
  width: 20px;
}

.filters-menu {
  padding: 0 $space-8;
  width: 100%;
}

.filter-item {
  align-items: flex-start;
  border-radius: 8px;
  color: map-get($colors, 'n1-400');
  display: flex;
  gap: $space-8;
  justify-content: space-between;
  padding: $space-8 $space-16;
  transition: background-color 0.2s ease-out;
  width: 100%;

  @include media($mq-md) {
    &:enabled:hover {
      background-color: map-get($colors, 'n1-100');
    }
  }

  &:disabled {
    color: map-get($colors, 'n1-300');

    .MuiCheckbox-root.filter-checkbox {
      color: map-get($colors, 'n1-200');
    }
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

}

.MuiCheckbox-root.filter-checkbox {
  padding: 0;

  &.Mui-disabled svg {
    fill: map-get($colors, 'n1-100');

    path {
      stroke: map-get($colors, 'n1-200');
    }
  }
}

.filter-label {
  flex: 1;
  text-align: left;
}

.filters-clear {
  color: map-get($colors, 'n1-400');
  margin: $space-8 auto;
  text-align: center;

  button {
    align-items: center;
    color: inherit;
    display: flex;
    gap: $space-4;
    margin: auto;
    padding: $space-4 18px $space-4 0;

    @include media($mq-md) {
      &:hover:enabled {
        color: map-get($colors, 'black');
      }
    }

    &:disabled {
      color: map-get($colors, 'n1-200');
    }
    
  }
}
