.header-location {
  padding: 0 20px;
  position: relative;
}

.header-location-container {
  .dropdown-text {
    margin-left: 3px;
  }

  .dropdown-button {
    border: 1px solid map-get($colors, 'n1-200');
    font-size: 14px;
    height: 32px;
    margin: 3px auto;
    padding: 0 14px;
    text-wrap: nowrap;

    .svg-pin-icon {
      margin-right: 4px;
    }

    .svg-chevron-right {
      transform: rotate(90deg);
      transition: transform 0.2s ease-out;
    }
  }

  &.open {
    .svg-chevron-right {
      transform: rotate(-90deg);
    }
  }
}

.header-location-dropdown {
  left: 0;
  position: absolute;
  right: 0;
  top: 78px;
  z-index: 100;

  &:before {
    background: map-get($colors, 'white');
    content: '';
    height: 27px;
    left: 50%;
    position: absolute;
    top: -13px;
    transform: translate(-50%) rotate(45deg);
    width: 27px;
    z-index: 102;
  }

  &:after {
    box-shadow: 0 0 8px 0 rgba(map-get($colors, 'black'), 0.25);
    content: '';
    height: 27px;
    left: 50%;
    position: absolute;
    top: -13px;
    transform: translate(-50%) rotate(45deg);
    width: 27px;
    z-index: 100;
  }

  .location-name {
    align-items: center;
    display: flex;
    margin: 0 0 8px 0;
  }

  .location-address {
    display: block;
    text-align: left;
  }

  .location-zip,
  .location-phone {
    display: block;
    margin-bottom: 16px;
    text-align: left;
  }

  .button-secondary {
    font-size: 16px;
    font-weight: 400;
    height: 40px;
    letter-spacing: 0.5px;
    text-wrap: nowrap;
    width: 244px;
  }
}

.location-dropdown-wrapper {
  left: calc(-144px + 50%);
  position: relative;
  width: 288px;
}

.location-dropdown-container {
  background: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(map-get($colors, 'black'), 0.25);
  padding: 18px 24px 24px 24px;
  position: relative;
  z-index: 101;
}
