.my-health-my-data-container {
  table {
    border-collapse: collapse;
  }

  h1 {
    margin-bottom: $space-24;
  }

  h2,
  h3 {
    margin-bottom: $space-8;
  }

  p {
    margin-bottom: $space-16;
  }

  td,
  th {
    border: 1px solid map-get($colors, 'black');
    padding: $space-12;
    text-align: left;
  }

  ul {
    margin: 0 0 0 $space-16;
  }

  li {
    list-style-type: disc;
  }

  button {
    color: inherit;
  }
}
