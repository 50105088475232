.footer-secondary {
  &.footer-container {
    background-color: map-get($colors, 'white');
    border-top: 1px solid map-get($colors, 'n1-200');
    padding: $space-24 0;
  }

  .footer {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin: 0 auto;
    max-width: $max-container;

    @include media($mq-md) {
      flex-direction: row;
    }
  }

  .footer-nav-list {
    display: flex;
    flex-direction: column;
    margin: 0 0 $space-24 0;

    @include media($mq-md) {
      flex-direction: row;
      margin: 0;
    }
  }

  .footer-nav-item {
    margin: 0 0 $space-28 0;
    text-align: center;

    @include media($mq-md) {
      margin: 0 $space-40 0 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  button.footer-nav-link {
    color: map-get($colors, 'n1-300');
    font-size: rem(16px);
    font-weight: 500;
    text-decoration: none;

    @include media($mq-md) {
      font-size: rem(14px);
    }
  }

  .footer-copyright {
    color: map-get($colors, 'n1-300');
    font-size: rem(14px);
  }
}
