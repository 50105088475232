.header {
  &.header-secondary {
    box-shadow: 0 4px 8px 0 rgba(map-get($colors, 'black'), 0.1);
  }
}

.header-secondary {
  .header-top {
    display: flex;

    &:after {
      content: '';
      flex: 1;
    }
  }

  .header-logo-image {
    background-position: 0 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 45px;

    @include media($mq-lg) {
      height: 65px;
    }

    &.desktop {
      display: none;

      @include media($mq-md) {
        display: block;
      }
    }

    &.mobile {
      @include media($mq-md) {
        display: none;
      }
    }
  }
}

.header-secondary-headline {
  line-height: 1;
}

.header-secondary-center-content {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  margin: 0 $space-16;
}

.logo,
.fake-spacer-div {
  min-width: $logo-sm-width;

  @include media($mq-md) {
    min-width: $logo-lg-width;
  }
}
