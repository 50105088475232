/* stylelint-disable selector-class-pattern */

$border-height: 8px;

// needs to be on top of everything
.MuiDialog-root {
  &.MuiModal-root {
    z-index: $zi-dialog-root !important;
  }

  .MuiPaper-rounded {
    border-radius: 8px;
  }
}

.modal {
  box-shadow: 0 4px 4px 0 rgba(map-get($colors, 'black'), 0.1);
  padding: $space-48 $space-24;

  @include media($mq-md) {
    border-radius: 8px !important;
    padding: $space-48 $space-64;
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .text-color-wrapper {
    color: map-get($colors, 'n1-500');
    display: flex;
    flex-direction: column;
  }
}

.modal-close-btn {
  color: map-get($colors, 'n1-500');
  display: block;
  height: 48px;
  position: absolute;
  right: 0;
  top: $border-height;
  width: 48px;
}

.modal-top-border {
  // NOTE: this var isn't available here
  // so we pass the var in to the component and use inline styles
  // background-color: var(--primary-color, map-get($colors, 'n1-500'));
  height: $border-height;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  @include media($mq-md) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
