.modal.sessiontimeout-modal {
  text-align: center;

  @include media($mq-md) {
    max-width: 550px;
  }
}

.countdown-num {
  color: map-get($colors, 's2-500');
  margin: $space-16 0;

  &.typography {
    @include media($mq-ltmd) {
      font-size: 22px;
    }
  }
}

.sessiontimeout-content {
  align-items: center;
  display: flex;
  justify-content: center;
}

.sessiontimeout-cta {
  margin: $space-32 auto 0 auto;
  max-width: 240px;
  width: 100%;
}
