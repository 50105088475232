.upload-preview-modal {
  &.modal {
    background-color: map-get($colors, 'transparent');
    border-radius: 0 !important;
    box-shadow: none;
  }

  .modal-close-btn {
    background-color: rgba(map-get($colors, 'white'), 0.75);
    border-radius: 100%;
    height: 32px;
    margin: $space-16 $space-20 0 0;
    padding: $space-4;
    width: 32px;

    svg {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .upload-preview-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    .upload-image-wrapper {
      background-color: map-get($colors, 'white');
      border-radius: 8px;
      position: relative;
    }
  }
}
