.order-status {
  @include media($mq-ltmd) {
    padding: 0;
  }

  .bullet {
    margin: 0 $space-8;
  }

  .print-button-desktop {
    display: none;

    @include media($mq-md) {
      display: flex;
    }
  }

  .print-button-mobile {
    margin: $space-24;

    @include media($mq-md) {
      display: none;
    }

    button {
      width: 100%;
    }
  }

  .order-details {
    display: flex;
    flex-direction: column;

    @include media($mq-md) {
      flex-direction: row;
      margin-bottom: $space-40;
    }
  }

  .order-summary {
    border-bottom: 1px solid map-get($colors, 'n1-200');
    margin-bottom: $space-16;
    padding: $space-24;

    @include media($mq-md) {
      border-bottom: 0;
      margin-bottom: 0;
      padding: 0;
      width: 315px;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: $space-16;
      }
    }

    .vertical-row {
      &:not(:last-child) {
        margin-bottom: $space-16;
      }

      > span {
        display: block;
      }

      .row-title {
        margin-bottom: $space-8;
      }
    }

    .add-on-row {
      display: flex;
      margin-bottom: $space-8;

      >:last-child {
        flex: 1;
      }
    }

    .color-dot {
      border: 1px solid transparent;
      border-radius: 50%;
      display: inline-block;
      height: 10px;
      margin-left: $space-8;
      width: 10px;

      &.white {
        border: 1px solid map-get($colors, 'n1-200');
      }
    }

    .border-pill {
      border-radius: 4px;
      color: map-get($colors, 'white');
      display: inline-flex;
      margin-left: $space-4;
      padding: 2px 8px;

      &.white {
        border: 1px solid map-get($colors, 'n1-200');
        color: map-get($colors, 'n1-500');
      }
    }
  }

  .cake-preview {
    height: 180px;
    position: relative;

    @include media($mq-md) {
      flex: 1;
      height: 375px;
    }

    img {
      object-fit: contain;
    }
  }

  .pickup-details {
    border-bottom: 1px solid map-get($colors, 'n1-200');
    display: flex;
    flex-direction: column;
    padding: $space-8 $space-24 0;

    @include media($mq-md) {
      border: 1px solid map-get($colors, 'n1-200');
      border-radius: 8px;
      flex-direction: row;
      padding: $space-32 $space-24;
    }

    > div {
      width: 100%;
    }
  }

  .group {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: $space-24;

    @include media($mq-md) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .title {
    margin-bottom: $space-8;
  }

  a {
    text-decoration: underline;
    transition: 0.2s ease-out;

    &:hover {
      color: map-get($colors, 'n1-300');
    }
  }
}

.order-status-header {
  display: flex;
  flex-direction: column;
  padding: $space-24;

  @include media($mq-md) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: $space-40;
    padding: 0;
  }
}
