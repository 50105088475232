.carousel-zoom-btn {
  bottom: 30px;
  height: 40px;
  position: absolute;
  right: 5px;
  transform: scale(1);
  transition: transform 0.2s cubic-bezier(0, 2.08, 0.58, 1);
  width: 40px;
  z-index: 1;

  &.scaled-down {
    transform: scale(0);
    transition-timing-function: ease-out;
  }
}

.carousel-image-slide,
.carousel-video-slide {
  padding-bottom: 100%;
}

.swiper-zoom-container,
.carousel-video-responsive {
  background-color: map-get($colors, 'black');
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding-bottom: 0;
  position: absolute;
  right: 0;
  top: 0;

  iframe {
    border: 0 none;
    width: 100%;
  }
}

.swiper-zoom-container {
  background-color: map-get($colors, 'white');
}

.zoom-in-svg {
  g {
    path {
      fill: var(--primary-color, map-get($colors, 'n1-500'));
    }
  }

  rect {
    stroke: var(--primary-color, map-get($colors, 'n1-500'));
  }
}

.zoom-out-svg {
  path {
    fill: var(--primary-color, map-get($colors, 'n1-500'));
  }

  rect {
    stroke: var(--primary-color, map-get($colors, 'n1-500'));
  }
}
