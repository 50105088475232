.add-ons-back {
  background-color: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: 24px;
  color: map-get($colors, 'n1-500');
  display: flex;
  margin-bottom: $space-16;
  padding: 8px 16px;
}

.add-ons-wrapper {
  background-color: map-get($colors, 'n1-100');
  border-radius: 8px;
  padding: $space-16 $space-24;
}

.add-on-card {
  align-items: center;
  background-color: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: 8px;
  box-shadow: inset 0 0 0 4px transparent;
  color: map-get($colors, 'n1-500');
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  padding: 16px;
  position: relative;
  transition: top 0.2s ease-out, box-shadow 0.2s ease-out;
  width: 100%;

  @include media($mq-md) {
    &:hover {
      box-shadow: inset 0 0 0 4px transparent, 0 8px 8px rgba(0, 0, 0, 0.25);
      top: -2px;
    }
  }

  .title {
    align-items: center;
    display: flex;
    flex: 1;
    margin-top: $space-8;
    text-align: center;
  }

  .cta {
    align-items: center;
    color: map-get($colors, 'n1-300');
    display: flex;
    flex-direction: row;
    margin-top: $space-16;

    svg path {
      fill: map-get($colors, 'n1-300');
    }
  }

  .buttons {
    height: 35px;
    margin-top: $space-8;
  }

  &.highlighted {
    border: 1px solid var(--primary-color);
    box-shadow: inset 0 0 0 3px var(--primary-color);
    position: relative;
    transition: top 0.2s ease-out, box-shadow 0.2s ease-out;

    @include media($mq-md) {
      &:hover {
        box-shadow: inset 0 0 0 3px var(--primary-color), 0 8px 8px rgba(0, 0, 0, 0.25);
        top: -2px;
      }
    }
  }
}

.add-on-image {
  height: 120px;
  max-height: 120px;
  max-width: 120px;
  width: auto;
}

.add-button {
  border: 1px solid map-get($colors, 'n1-500');
  border-radius: 16px;
  color: map-get($colors, 'n1-500');
  padding: 4px 16px;
}

.add-remove-bar {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.add-on-item-cart-amount {
  margin: 0 $space-4;
  text-align: center;
  width: 32px;
}

.add-remove-button {
  background-color: map-get($colors, 'n1-500');
  border-radius: 50%;
  display: flex;
  height: 24px;
  padding: 4px;
  width: 24px;

  svg {
    height: 100%;
    width: 100%;
  }
}
