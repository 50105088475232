/* stylelint-disable selector-class-pattern */

$loader-size: 5px;

.waiting-for-photo-modal {
  text-align: center;

  @include media($mq-md) {
    max-width: 550px;
  }

  .circle-loading-indicator {
    border-left: $loader-size solid var(--primary-color, map-get($colors, 'p1-400'));
    border-right-width: $loader-size;
    border-top: $loader-size solid var(--primary-color, map-get($colors, 'p1-400'));
    height: 50px;
    margin: 0 auto;
    width: 50px;
  }

  .headline {
    margin-bottom: $space-16;
  }

  .instructions {
    margin-top: $space-16;

    br {
      content: ' ';

      @include media($mq-md) {
        content: initial;
        display: unset;

        // &:after {
        //   content: '';
        // }
      }

      &:after {
        content: ' ';
      }
    }
  }

  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
