.customization-tile {
  align-items: center;
  background: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 192px;
  justify-content: center;
  outline: 2px solid map-get($colors, 'transparent');
  padding: 8px;
  transition-duration: 0.2s;
  transition-property: border, outline;
  transition-timing-function: ease-out;

  @include media($mq-md) {
    height: 202px;
  }

  @include media($mq-lg) {
    height: 220px;
  }

  &.customization-tile-selected {
    border: 1px solid var(--primary-color, map-get($colors, 'n1-200'));
    outline: 2px solid var(--primary-color, map-get($colors, 'n1-200'));
  }
}

.customization-tile-button {
  border-radius: 8px;
  color: map-get($colors, 'n1-500');
  position: relative;
  transition: top 0.2s ease-out, box-shadow 0.2s ease-out;
  width: 100%;

  @include media($mq-md) {
    &:hover {
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
      top: -2px;
    }
  }

  .description,
  .calories {
    padding-bottom: 8px;
  }
}
