/* stylelint-disable selector-class-pattern */

html,
body {
  color: map-get($colors, 'n1-500');
  font-family: var(--basisGrotesqueFont);
  margin: 0;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

a {
  color: inherit;
  text-decoration: none;
}

pre {
  background-color: #EAEAEA;
  border-radius: 3px;
  max-width: 500px;
  padding: 20px;
  white-space: pre-wrap;
  width: 500px;
}

.wrapper {
  margin: 0 auto;
  max-width: $max-container;
  padding: $wrapper-padding 0;
}

/* NOTE:
I'm not sure why I initially added a media query here,
so I removed it, but we can add it back if we need to
*/
.add-well,
.footer .container,
.hero,
.wrapper {
  outline: 0 none;
  padding-left: $wrapper-padding;
  padding-right: $wrapper-padding;
  width: 100%;
}

/* removes X button from text inputs */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

// MUI has a media query that we
// don't want this is to override it
.MuiGrid-grid-sm-12 {
  flex-basis: 100%;
}

// these classes allow us to use the next/image component
// with layout=fill in the way we want it to work
.image-container {
  position: relative;
  width: 100%;

  > span {
    position: unset !important;
  }

  .image {
    object-fit: contain;
    position: relative !important;
    width: 100% !important;
  }
}

// force footer to bottom of viewport
// ------------------------------------
#__next {
  height: 100%;
}

#page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

main {
  flex-grow: 1;
}
// ------------------------------------

pre {
  max-width: 100%;
  width: 100%;
}

.is-hidden {
  display: none;
}

.is-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.hide-editor {
  .customization-editor-wrapper {
    position: absolute;
    top: -900%;
  }
}

address {
  font-style: normal;
}

textarea {
  border: 1px solid map-get($colors, 'n1-300');
  border-radius: 8px;
  color: map-get($colors, 'n1-500');
  outline: 2px solid map-get($colors, 'transparent');
  padding: $space-16 $space-24;
  width: 100%;

  &::placeholder {
    color: map-get($colors, 'n1-300');
  }

  &:focus-visible {
    border: 1px solid map-get($colors, 'transparent');
    outline: 2px solid map-get($colors, 'n1-500');
  }
}

.MuiInputBase-input {
  &::placeholder {
    color: map-get($colors, 'n1-300');
    opacity: 1;
  }
}

.circle-loading-indicator {
  animation: loading-indicator 700ms linear infinite;
  border-bottom: 0 none;
  border-left: 0 none;
  border-radius: 50%;
  border-right: 2px solid transparent;
  border-top: 2px solid map-get($colors, 'p1-400');
  height: 30px;
  width: 30px;
}

@keyframes loading-indicator {
  to {
    transform: rotate(360deg);
  }
}

@media print {
  .footer-container,
  .header-container {
    visibility: hidden;
  }
}

.placeholder-loader {
  display: flex;
  justify-content: center;
  padding: $space-32;
  position: relative;

  &.large {
    height: 100vh;
  }

  .loader-container {
    position: relative;
    right: auto;
    top: auto;
    transform: none;
  }
}
