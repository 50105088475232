/* stylelint-disable selector-class-pattern, max-nesting-depth */

$input-border-radius: 600px;
$input-small-height: 40px;
$input-medium-height: 48px;
$input-large-height: 60px;

$input-border-color: var(--primary-color, map-get($colors, 'n1-400'));

.text-input { 

  // get rid of the weird 2px border that MUI applies
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid $input-border-color;
  }

  .MuiInputBase-root {
    font-family: $font-family;
    padding: 0;
  }

  // set label's initial font size
  .MuiFormLabel-root {
    color: map-get($colors, 'input-placeholder');
    font-family: $font-family;
    font-size: rem(17px);

    &.MuiInputLabel-shrink {
      background-color: map-get($colors, 'white'); // Fix for Safari issue with border radius and legends
      color: map-get($colors, 'input-placeholder');
      padding-left: 5px;
      padding-right: 5px; // Prevent border from getting too close to asterisk
    }

    &.Mui-error {
      color: map-get($colors, 'input-placeholder');
    }
  }

  // set border radius on input
  .MuiInputBase-formControl {
    background-color: map-get($colors, 'white');
    border-radius: $input-border-radius;

    // keep the border color green (p2-500) on hover to override the default black
    &:focus,
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: $input-border-color;
      }
    }

    &.Mui-error {
      &:focus,
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: $input-border-color;
        }
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
      }
    }
  }

  // change border color for base state
  .MuiOutlinedInput-notchedOutline {
    border-color: $input-border-color;
    padding: $space-4 $space-12 $space-8; // Fix for issue with borders and legends in iOS

    &.Mui-error {
      border-color: map-get($colors, 's2-500');
    }
  }

  // always set input box-sizing
  // so we can have different sizes
  .MuiInputBase-input {
    box-sizing: border-box;
    color: map-get($colors, 'n1-500');
    font-family: $font-family;
    font-size: rem(17px);
  }

  // change color of "required" asterisk
  .MuiFormLabel-asterisk {
    color: map-get($colors, 's2-500');
  }

  .MuiFormHelperText-root {
    color: map-get($colors, 'input-placeholder');
    font-family: $font-family;

    &.Mui-error {
      color: map-get($colors, 's2-500');
    }
  }
}

// sizes below

// leaving this for now
// .text-input-small {
//   .MuiInputBase-input {
//     height: $input-small-height;
//   }
// }

.text-input-medium {

  // placeholder text and input placement
  .MuiInputBase-input {
    height: $input-medium-height;

    // when using a <TextField /> component
    transform: translate(8px, -1px) scale(1);

    // when using an <Input /> component
    // transform: translate(14px, 16px) scale(1);
  }

  // label placement
  .MuiFormLabel-root {
    // when using a <TextField /> component
    transform: translate(22px, 15px) scale(1);

    // this causes a weird white space that I can't figure out
    // something with the legend element
    // &.MuiInputLabel-shrink {
    //   background-color: red;
    //   transform: translate(122px, -6px) scale(0.75);
    // }
  }

  // make the icon same height as input
  .MuiInputAdornment-root {
    height: $input-medium-height;
    max-height: none;
  }
}

// leaving this for now
// .text-input-large {
//   .MuiInputBase-input {
//     height: $input-large-height;
//   }
// }

// Override the default red outline with the more accessible s2-500 red
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: map-get($colors, 's2-500') !important;
}
