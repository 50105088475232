.location-list {
  display: flex;
  flex-direction: column;
  padding: 0 $space-16 0 0;

  @include media($mq-md) {
    height: 640px;
    overflow-y: auto;
  }
}

.error-no-location-results {
  margin: 0 16px 0 0;
}
