// ---------------------------------------------------------
/*
    normalize v8.0.1 + additions from DecoPac

    evaluate on yearly basis as the engines change:
      - webkit = chrome, safari, edge 79+
      - gecko = firefox
      - trident = ie, edge 78-
*/
// ---------------------------------------------------------

* {
  box-sizing: border-box;
  font-family: $font-family;
  margin: 0;
  padding: 0;
}

html {
  // force size
  font-size: 16px;
  line-height: 1.5;
  // scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
}

body {
  font-style: normal;
  font-weight: normal;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

hr {
  height: 0;
  overflow: visible;
}

b,
strong {
  font-style: normal;
  font-weight: bold;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: inherit;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -$space-4;
}

sup {
  top: -$space-8;
}

ol,
ul {
  list-style-type: none;
}

div,
span {
  outline: 0;
}

img,
video,
audio {
  border: 0;
  display: block;
  height: auto;
  max-width: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  border-radius: 0;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: rem(1px) dotted map-get($colors, 'p1-500');
}

fieldset {
  border: 0;
  padding: $space-4 $space-12 $space-8;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: rem(-2px);
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template,
[hidden] {
  display: none;
}

// interactive elements
// a,
// button,
input,
select,
textarea {
  transition: $timing-0;
}

// link color
a {
  color: map-get($colors, 'p1-500');
}

button,
input[type='checkbox'],
input[type='radio'],
select {
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

select {
  @include appearance(none);
  background: transparent;
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// global table styles
table {
  border-collapse: collapse;
  margin: 0 0 $space-32 0;
}

table,
th,
td {
  border: 0;
}

th,
td {
  padding: $space-12 $space-24;
}

.table-headline {
  background-color: map-get($colors, 'blue-dark');
  color: map-get($colors, 'white');
}

.table-subhead {
  background-color: map-get($colors, 'gray-light');
  color: map-get($colors, 'gray-dark');
}

td {
  vertical-align: top;
}

#sitemap {
  margin: $space-32 0 $space-64 0;

  ul {
    list-style-type: none;
    margin: 0 0 0 12px;
  }
}

// Container
// ------------------------------------------------------------------
.container {
  margin: 0 auto;
  max-width: $max-container;
}
