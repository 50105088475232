.subtext {
  background-color: map-get($colors, 'white');
  border: 1px solid map-get($colors, 'n1-200');
  border-radius: 8px;
  color: map-get($colors, 'n1-400');
  display: flex;
  justify-content: center;
  margin: $space-32 0 0 0;
  padding: $space-24;
  text-align: center;
}
