/* stylelint-disable selector-class-pattern */

$autofill-anchor-height: 100px;

.search-form {
  display: flex;
  justify-content: right;
}

.text-input {
  &.search-input {
    max-width: rem(457px);
    width: 100%;

    // remove right padding for "adornments" or icons
    .MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }
  }

  fieldset {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: -5px;
  }
}

// remove clear button
#search::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.MuiBackdrop-root.mobile-search-wrapper {
  background-color: rgba(map-get($colors, 'n1-500'), 0.5);
  z-index: $zi-mobile-search;
}

.mobile-search-input-wrapper {
  background-color: var(--primary-color, map-get($colors, 'n1-400'));
  box-shadow: 0 2px 4px rgba(map-get($colors, 'black'), 0.1);
  height: $header-sm-height;
  left: 0;
  position: fixed;
  right: 0;
  top: -$header-sm-height;
  transition: top 0.2s ease-out;
  z-index: $zi-mobile-search-input-wrapper;

  @include media($mq-md) {
    height: $header-md-height;
    top: -$header-md-height;
  }

  &.visible {
    top: 0;
  }
}

.mobile-search-input-inner {
  align-items: center;
  display: flex;
  height: 100%;
}

.mobile-search-input {
  border: 1px solid var(--primary-color, map-get($colors, 'n1-500'));
  border-radius: 600px;
  flex-grow: 1;
  height: $space-40;
  margin: 0 $space-24 0 0;
  outline: none;
  padding: 0 $space-24;

  @include media($mq-md) {
    height: $space-48;
  }
}

.mobile-search-close-btn {
  height: 100%;
  padding: 0 $space-24;

  path {
    fill: map-get($colors, 'n1-100');
  }
}

.search-wrapper {
  line-height: 1;

  @include media($mq-lg) {
    display: none;
  }

  button {
    line-height: 1;
  }
}

.search-wrapper-lg {
  display: none;
  position: relative;

  @include media($mq-lg) {
    display: block;
  }
}

.autocomplete {
  background-color: map-get($colors, 'white');
  color: map-get($colors, 'n1-500');
  font-size: rem(17px);
  height: 100vh;
  overflow: scroll;
  padding: 0 0 130px 0;
  position: absolute;
  width: 100%;
  z-index: $zi-search-autocomplete;

  @include media($mq-lg) {
    border: 1px solid var(--primary-color, map-get($colors, 'n1-200'));
    border-radius: 16px;
    height: unset;
    overflow: auto;
    padding: 0;
    position: absolute;
    top: 55px;
  }
}

.ac-result {
  border-bottom: 1px solid map-get($colors, 'n1-200');
  position: relative;

  @include media($mq-lg) {
    border: 0;

    // inset border
    &:after {
      border-bottom: 1px solid map-get($colors, 'n1-200');
      content: '';
      display: block;
      margin: 0 auto;
      width: calc(100% - 48px);
    }

    // need same spacing above
    &:before {
      content: '';
      display: block;
    }
  }
}

.ac-anchor {
  align-items: center;
  background-color: map-get($colors, 'transparent');
  display: flex;
  height: 80px;
  padding: 0 $space-44 0 $space-16;
  width: 100%;

  @include media($mq-lg) {
    height: $autofill-anchor-height;
    padding: 0 $space-16 0 $space-24;

    &:focus,
    &:hover {
      .ac-result-copy-wrapper {
        background-color: var(--primary-color, map-get($colors, 'n1-200'));
        color: var(--primary-label-color, map-get($colors, 'n1-500'));

        path {
          fill: var(--primary-label-color, map-get($colors, 'n1-500'));
        }
      }
    }
  }

  em {
    font-style: normal;
    font-weight: 400;
  }

  * {
    pointer-events: none;
  }
}

.ac-anchor-hover {
  overflow: hidden;
}

.ac-result-image-wrapper {
  display: flex;
  flex: 0 0 80px;
  justify-content: center;
  margin: 0 $space-8 0 0;

  @include media($mq-lg) {
    flex: 0 0 120px;
  }
}

.ac-result-image {
  background-color: map-get($colors, 'white');
  max-height: 80px;
  width: auto;

  @include media($mq-lg) {
    max-height: $autofill-anchor-height;
  }
}

.ac-result-copy-wrapper {
  align-items: center;
  display: flex;
  overflow: hidden;
  padding: 0 $space-8 0 $space-8;
  transition: background-color 0.2s ease-out;
  width: 100%;

  @include media($mq-lg) {
    border-radius: 4px;
    height: 64px;
    padding: 0 $space-40 0 $space-16;
  }
}

.ac-result-copy {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.2s ease-out;
  white-space: nowrap;
}

.ac-result-arrow {
  position: absolute;
  right: $space-24;
  top: 50%;
  transform: translateY(-50%);
  @include media($mq-lg) {
    right: $space-32;
  }

  path {
    transition: fill 0.2s ease-out;
  }
}

.ac-result-magnify {
  margin: 0 9px 0 0;

  path {
    fill: var(--primary-color, map-get($colors, 'n1-500'));
    transition: fill 0.2s ease-out;
  }
}

.ac-search-btn-wrapper {
  padding: $space-16;
  position: relative;
}

.ac-search-btn {
  border-radius: 4px;
  color: map-get($colors, 'n1-500');
  display: block;
  padding: 6px 12px;
  text-align: left;
  width: 100%;

  &:focus,
  &:hover {
    background-color: var(--primary-color, map-get($colors, 'n1-200'));
    color: var(--primary-label-color, map-get($colors, 'n1-500'));

    .ac-result-magnify path {
      fill: var(--primary-label-color, map-get($colors, 'n1-500'));
    }
  }

  // add some padding to the searched text
  strong {
    padding: 0 $space-24 0 0;
  }
}

.search-container {
  @include media($mq-lg) {
    flex-grow: 1;
  }

  .text-input-medium .MuiInputBase-input {
    line-height: 48px;
  }
}

.search-submit-btn {
  padding: 0 0 0 40px;

  @include media($mq-ltlg) {
    padding: 0;
    position: absolute;
    right: 25px;
  }
}

.search-clear-btn {
  display: none;
  height: 20px;
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;

  @include media($mq-lg) {
    right: 60px;
  }
  @include media($mq-ltlg) {
    right: 100px;
  }

  path {
    fill: map-get($colors, 'n1-400');
  }

  &.visible {
    display: block;
  }
}
