/* stylelint-disable selector-class-pattern */

$list-container-width-md: 300px;
$list-container-width-lg: 360px;

.locations-grid {
  background-color: map-get($colors, 'n1-100');
  margin-left: -$wrapper-padding;
  margin-right: -$wrapper-padding;
  padding: $space-24;

  @include media($mq-md) {
    border-radius: 8px;
    margin-left: 0;
    margin-right: 0;
  }

  // override tab button styling
  .MuiTabs-root {
    margin: 0 0 $space-16 0;

    .MuiButtonBase-root {
      color: map-get($colors, 'n1-500');
      flex-basis: 50%;
      font-family: $font-family;
      font-size: rem(18px);
      text-transform: none;
    }

    .MuiTabs-indicator {
      background-color: var(--primary-color, map-get($colors, 'n1-500'));
      border-radius: 4px;
      height: 4px;
    }
  }
}

.locations-container {
  display: flex;
  flex-direction: row;
  height: 640px;
}

.locations-list-container {
  @include media($mq-md) {
    flex-basis: $list-container-width-md;
    max-width: $list-container-width-md;
  }

  @include media($mq-lg) {
    flex-basis: $list-container-width-lg;
    max-width: $list-container-width-lg;
  }
}

.locations-map-container {
  flex: 1;
  margin: 0 0 $space-16 0;
}

.locations-desktop {
  display: none;

  @include media($mq-md) {
    display: block;
  }
}

.locations-mobile {
  display: block;
  
  @include media($mq-md) {
    display: none;
  }
}
