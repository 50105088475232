/* stylelint-disable selector-class-pattern */
.footer-container {
  background-color: map-get($colors, 'n1-100');
  padding: $space-40 0 $space-56;
}

.footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: $max-container;

  @include media($mq-lg) {
    flex-direction: row;
  }
}

.footer-logo {
  align-items: center;
  display: flex;

  @include media($mq-lg) {
    align-self: start;
  }
}

.footer-logo-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  filter: grayscale(1);
  height: 72px;
  min-width: 214px;
  mix-blend-mode: multiply;
  opacity: 0.6;

  @include media($mq-lg) {
    height: 56px;
    min-width: 166px;
  }

  @include media($mq-lg) {
    background-position: 0;
    height: 72px;
    min-width: 214px;
  }

  &.desktop {
    display: none;

    @include media($mq-md) {
      display: block;
    }
  }

  &.mobile {
    @include media($mq-md) {
      display: none;
    }
  }
}

.footer-bottom {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;

  @include media($mq-lg) {
    align-items: flex-end;
    text-align: right;
  }
}

.footer-nav-list {
  display: flex;
  flex-direction: column;
  margin: 30px 0 0 0;

  @include media($mq-md) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    &:after {
      content: '';
      width: 100%;
    }
  }

  @include media($mq-lg) {
    flex-wrap: nowrap;
    justify-content: right;
    margin: 0;

    &:after {
      width: auto;
    }
  }
}

.footer-nav-list-secondary {
  display: flex;
  flex-direction: column;
  margin: 30px 0 0 0;

  @include media($mq-md) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;

    &:after {
      content: '';
      width: 100%;
    }

    .footer-nav-link {
      margin-top: 3px;
      white-space: nowrap;
    }
  }

  @include media($mq-lg) {
    flex-wrap: nowrap;
    justify-content: right;
    margin: 20px 0 0 0;

    &:after {
      width: auto;
    }

    .footer-nav-link {
      margin-top: 0;
    }
  }
}

.footer-nav-item {
  margin: 0 0 $space-28 0;

  @include media($mq-md) {
    margin: 0 $space-24 0 0;

    &:last-child {
      order: 1;
    }
  }

  &:last-child {
    margin: 0;
  }
}

button {
  &.footer-nav-link {
    color: map-get($colors, 'n1-400');
    font-size: rem(16px);
    font-weight: 700;
    text-decoration: none;
  }

  &.footer-nav-language {
    background: transparent;
    border: 1px solid map-get($colors, 'n1-200');
    border-radius: 8px;
    color: map-get($colors, 'n1-400');
    font-size: rem(14px);
    font-weight: 700;
    height: 32px;
    margin: auto;
    min-width: 137px;
    padding: 1px 9px;
    text-decoration: none;

    @include media($mq-md) {
      margin-top: 0;
    }

    @include media($mq-lg) {
      margin-top: -4px;

      &:hover {
        border: 1px solid map-get($colors, 'n1-300');
      }
    }

    .svg-globe-icon {
      height: 25px;
      padding: 5px 0;
      width: 25px;
    }

    .svg-chevron-right {
      margin: 2px 0 0 3px;
      transform: rotate(90deg);
      transition: transform 0.25s linear;
    }

    &.open {
      background: map-get($colors, 'white');
      border: 1px solid map-get($colors, 'n1-200');
      border-radius: 8px 8px 0 0;
      cursor: pointer;

      .svg-chevron-right {
        transform: rotate(-90deg);
        transition: transform 0.25s linear;
      }
    }
  }
}

// Overrides Mui Menu pop up styles
.footer-language-menu {
  &.MuiPaper-root {
    border: 1px solid map-get($colors, 'n1-200');
    border-radius: 0 0 8px 8px;
    box-shadow: none;
    margin-top: -1px;
    min-width: 137px;

    .MuiMenu-list {
      padding: 0;
    }

    .MuiMenuItem-root {
      border-radius: 5px;
      color: map-get($colors, 'n1-400');
      display: flex;
      justify-content: space-between;
      margin: 5px;
      min-height: 30px;
      padding: 0 0 0 7px;

      &.Mui-selected {
        background-color: transparent;
        font-weight: bold;
      }

      .svg-chevron-right {
        margin-top: -2px;
      }
    }
  }
}

.footer-fineprint {
  color: map-get($colors, 'n1-400');
  font-size: rem(15px);
  line-height: rem(21px);
  margin: 30px 0 0 0;
  max-width: 580px;

  @include media($mq-lg) {
    margin: 20px 0 0 50px;
    max-width: 520px;
  }
}

.footer-fineprint-link button {
  color: map-get($colors, 'n1-400');
  font-weight: 500;
  text-decoration: none;

  @include media($mq-md) {
    &:hover {
      text-decoration: underline;
    }
  }
}
