/* stylelint-disable selector-class-pattern */

.modal.photo-modal {
  max-width: 745px;

  @include media($mq-md) {
    padding: $space-64;
  }

  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.photo-modal-formats {
  margin: $space-8 0 0 0;
}

.photo-modal-copyright {
  margin: $space-24 0;
}


// file upload
// ------------------------------------------------------------------

.file-upload-input-container {
  margin: $space-40 0 $space-16 0;
}

.file-upload {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: $zi-file-upload;
}

// update styles for disabled button
.file-upload:disabled + .file-upload-label,
.file-upload:disabled + .file-upload-label:hover,
.file-upload:disabled + .file-upload-label:focus {
  cursor: not-allowed;
}

.file-upload + .file-upload-label {
  align-items: center;
  background-color: map-get($colors, 'p1-500');
  border-radius: 500px;
  color: map-get($colors, 'white');
  cursor: pointer;
  display: inline-flex;
  height: rem(56px);
  justify-content: center;
  width: rem(240px);
}

.file-upload-error {
  color: map-get($colors, 's2-500');
  justify-content: center;
  margin: 0 0 $space-32 0;
}
