.back-link {
  align-items: center;
  color: map-get($colors, 'n1-300');
  display: none;
  flex-direction: row;
  margin-bottom: $space-24;

  @include media($mq-md) {
    display: inline-flex;
  }

  .svg {
    margin-right: $space-8;
  }

  &:hover {
    color: map-get($colors, 'black');

    svg path {
      fill: map-get($colors, 'black');
    }
  }
}
