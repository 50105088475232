.breadcrumb {
  margin: 0 0 $space-24 0;

  @include media($mq-lg) {
    margin: $space-8 0 $space-32 0;
  }

  a,
  .typography-body {
    background-color: map-get($colors, 'white');
    color: map-get($colors, 'n1-500');
    font-size: rem(14px);
  }

  .separator {
    display: inline-block;
    text-align: center;
    width: 20px;
  }

  a:hover {
    text-decoration: underline;
  }
}

.breadcrumb-list {
  display: flex;
}
