/* stylelint-disable selector-class-pattern */

.modal.review-modal {
  max-width: 902px;

  @include media($mq-md) {
    max-width: 90vw;
    padding: $space-64;
  }

  @include media($mq-lg) {
    max-width: 936px;
  }

  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.review-modal-preview-wrapper {
  display: flex;
  height: 280px;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  @include media($mq-md) {
    height: 320px;
  }

  @include media($mq-lg) {
    height: 400px;
  }
  
  img.round {
    max-width: 310px;
    
    @include media($mq-md) {
      max-width: 335px;
    }

    @include media($mq-lg) {
      max-width: 400px;
    }
  }

  img.sheet {
    margin-bottom: 40px;
    margin-top: -40px;
    
    @include media($mq-md) {
      margin-bottom: 15px;
      margin-top: -10px;
      max-width: 280px;
    }

    @include media($mq-lg) {
      margin-bottom: 60px;
      margin-top: -20px;
      max-width: 100%;
    }
  }
}

.review-modal-hr {
  background-color: map-get($colors, 'n1-200');
  border: 0 none;
  height: 1px;
  margin: $space-24 0;

  @include media($mq-md) {
    display: none;
  }
}

.MuiGrid-root {
  &.review-modal-body-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0 $space-16;

    @include media($mq-md) {
      flex-direction: column;
    }
  }
}

.review-modal-body {
  
  @include media($mq-md) {
    padding-bottom: $space-20;
  }

  @include media($mq-lg) {
    padding-bottom: $space-28;
  }
}

.review-modal-cta-wrapper {
  display: flex;
  flex-direction: column;
}

.review-modal-return {
  align-items: center;
  display: flex;
  justify-content: center;
  
  .svg {
    margin-right: $space-4;
  }
}

.review-modal-headline {
  margin: 0 0 $space-16 0;
}

.review-modal-subhead {
  margin: 0 0 $space-8 0;
}

.review-modal-cta {
  margin: 0 auto $space-16 auto;
}
