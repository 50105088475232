.button-icon {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  line-height: 1;
  padding: 0;
  transition: 0.2s ease-out;
}

.button-icon-bg {
  border-radius: 50%;
  padding: $space-16;
}

.button-icon-label {
  color: map-get($colors, 'p1-500');
  font-size: rem(15px);
  margin: $space-8 0 0 0;
  text-align: center;
  transition: color 0.2s ease-out;
}

// prevents events from svgs, paths, etc from bubbling
// so that tracking will work correctly
.button-icon > * {
  pointer-events: none;
}

button > * {
  pointer-events: none;
}
